import { Table, TableHeader, TableRow, TableHeaderCell, TableCell, TableColumnSizingOptions, useTableFeatures, useTableColumnSizing_unstable, createTableColumn, TableColumnDefinition, MenuItem } from "@fluentui/react-components";
import React from "react";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";
import { ShareWithMeStudyResponseModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { useLoading } from "../../utils/loading-indicator.component";
import { processServerError } from "../../utils/helpers/error.helper";
import { getDashboardSharingClient } from "../../services/dashboard.service";
import { FileDisplayName } from "../../components/file-display-name/file-display-name.component";
import { ContextMenuType } from "../../files/context-menu/context-menu.component";
import { PersonDisplay } from "../../components/person-display/person-display.component";
import { HubConnection } from "@microsoft/signalr";
import { SensitivityStudyDetailsModal } from "../../sensitivity-studies/sensitivity-study-details-modal/sensitivity-study-details-modal.component";

type SharedWithMeMssTableProps = {
    isOptimumTest: boolean;
    hubConnection: HubConnection;
}

export const SharedWithMeMssTable: React.FC<SharedWithMeMssTableProps> = (props) => {

    const [isLoading, loadingService] = useLoading();
    const [selectedItem, setSelectedItem] = React.useState<ShareWithMeStudyResponseModel>();
    const [showDetailsModal, setShowDetailsModal] = React.useState<boolean>(false);
    const [items, setItems] = React.useState<ShareWithMeStudyResponseModel[]>([]);
    const contextMenuRef = React.createRef<ContextMenuType>();

    React.useEffect(() => {
        getSharedStudies();
    }, []);


    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        name: {
            minWidth: 100,
            defaultWidth: 400,
        },
        flowsheet: {
            minWidth: 300,
            defaultWidth: 400
        },
        sharedBy: {
            minWidth: 200,
            defaultWidth: 300
        },
        created: {
            minWidth: 100,
            defaultWidth: 200

        }
    });


    const getSharedStudies = async () => {
        loadingService.showLoading(`Loading ${props.isOptimumTest ? "optimum tests" : "studies"}...`, async (hideMessage) => {

            try {
                const client = getDashboardSharingClient();
                const studiesResp = await client.getSharedWithMeMssStudies(props.isOptimumTest);
                setItems(studiesResp ?? []);

            } catch (error) {
                processServerError(error, undefined, `An error occurred while getting ${props.isOptimumTest ? "optimum tests" : "studies"} that are shared with me.`);

            } finally {
                hideMessage();
            }

        });

    }

    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "shared": return true;

            default: return false;
        }
    }

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<ShareWithMeStudyResponseModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );


    return <div>
        <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()} >
            <TableHeader>
                <TableRow>
                    {columns.map((column) => (
                        <TableHeaderCell
                            onDragStart={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            className={`${isColumnCentered(column.columnId as string) ? 'files-table__cell--center' : ''} files-table__cell--bold`}
                            {...columnSizing_unstable.getTableHeaderCellProps(
                                column.columnId
                            )}
                        >
                            {column.renderHeaderCell()}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={isLoading}
                columnCount={4} loadingMessage="Loading..."
                itemCount={items ? items.length : 0}
                noItemsMessage="No items found.">
                {items && items.length > 0 && items.map((item) => {
                    return <TableRow
                        key={`study-${item.id}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => { setSelectedItem(item); setShowDetailsModal(true); }}
                        className={selectedItem && selectedItem.id == item.id ? "table-row-selected" : undefined}
                        onContextMenu={(ev) => { ev.preventDefault(); setSelectedItem(item); contextMenuRef.current?.showMenu(ev); }}
                    >
                        {columns.map((column) => (
                            <TableCell
                                className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                {...columnSizing_unstable.getTableCellProps(column.columnId)}
                            >
                                {column.renderCell(item)}
                            </TableCell>
                        ))}

                    </TableRow>
                })}


            </TableBodyWithLoading>
        </Table>
        {showDetailsModal && selectedItem &&
            <SensitivityStudyDetailsModal
                isOpened
                studyId={selectedItem.id!}
                studyName={selectedItem.name}
                isOptimumTest={props.isOptimumTest}
                hubConnection={props.hubConnection}
                onClose={() => { setShowDetailsModal(false); }} />}

    </div>
}
const columns: TableColumnDefinition<ShareWithMeStudyResponseModel>[] = [
    createTableColumn<ShareWithMeStudyResponseModel>({
        columnId: "name",
        renderHeaderCell: () => <>Name</>,
        renderCell: (item: ShareWithMeStudyResponseModel) => {
            return <span>{item.name}</span>;

        }
    }),
    createTableColumn<ShareWithMeStudyResponseModel>({
        columnId: "flowsheet",
        renderHeaderCell: () => <>Flowsheet</>,
        renderCell: (item: ShareWithMeStudyResponseModel) => {
            return <FileDisplayName fileUniqueIdentifier={item.fileUniqueIdentifier} fileVersionNumber={item.fileVersionNumber} hidePath />;

        }
    }),
    createTableColumn<ShareWithMeStudyResponseModel>({
        columnId: "sharedBy",
        renderHeaderCell: () => <>Shared by</>,
        renderCell: (item: ShareWithMeStudyResponseModel) => {

            return <PersonDisplay id={`person-${item.owner?.id}`}
                name={item.owner?.displayName}
                userPrincipalName={item.owner?.userPrincipalName} />;
        }
    }),

    createTableColumn<ShareWithMeStudyResponseModel>({
        columnId: "shared",
        renderHeaderCell: () => <>Shared</>,
        renderCell: (item: ShareWithMeStudyResponseModel) => {

            return <ShowLocalTime date={item.sharedAtUtc} multiline />;
        }
    })
];