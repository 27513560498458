import * as React from 'react';

import { ContextMenuWithData, ContextMenuWithDataType } from '../../../files/context-menu/context-menu.component';
import { Dropdown, Option, Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridProps, DataGridRow, Input, MenuItem, TableCellLayout, TableColumnDefinition, TableRowId, createTableColumn, tokens, TableBody, TableRow, TableCell } from '@fluentui/react-components';
import { FileRowResultModel, InputParameterResultModel, ISenarioDataElasticQuery, OutputParameterResultModel, ParameterFilter, ProcessingStatus, ResubmitRowPostModel, RowProcessingStatus, ScenarioDataResultModel, ScenarioResultModel, SenarioDataElasticQuery } from '../../../swagger-clients/excel-runner-api-clients.service';
import { FileModel, FilterResultModel, InputFilterParameterResultModel } from '../../../swagger-clients/s365-dashboard-v2-api-clients.service';
import { IFilterParamData } from '../../../sensitivity-studies/sensitivity-study-details/sensitivity-study-data/filter-parameter-picker.component';
import { _copyAndSort } from '../../../utils/helpers/array.helpers';
import { getScenariosClient } from '../../../services/excel-runner.service';
import { IDropdownOption } from '../../../utils/shared.types';
import { DismissRegular } from '@fluentui/react-icons';
import ReactPaginate from 'react-paginate';
import { DispatcherJobDetails } from '../../../components/dispatcher-job-details/dispatcher-job-details.component';
import FilterParameterPicker from '../../components/filter-parameter-picker/filter-parameter-picker.component';
import { ParameterDisplay } from '../../../components/parameter-display/parameter-display.component';
import { getUserToken } from '../../../services/dashboard.service';
import { toast } from 'react-toastify';
import { LoadingService } from '../../../utils/loading-indicator.component';
import { Skeleton, SkeletonItem } from '@fluentui/react-components';
import { DataGridScrollableWrapper, DataGridScrollableHeaderStyles } from '../../../utils/DataGridScrollableWrapper';
import { ExcelRunnerRowProcessingStatus } from '../excel-runner-row-processing-status.component';
import { SignificantFigures } from '../../../components/significant-figures/significant-figures.component';
import { useQuery } from '@tanstack/react-query';
import { AddExperimentRowsModal } from './add-experiment-rows-modal.component';
import { INewExperiment } from '../../edit-experiment/experiment.models';



interface IDataTabProps {
    selectedScenario: ScenarioResultModel;
    loadingService: LoadingService;
    filter: FilterResultModel;
    selectedFile: FileModel;
    onDataReloaded: () => void;
    isExample?: boolean;
}

export type DataTabType = { getData(): void; };

const DataTab = React.forwardRef<DataTabType, IDataTabProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            getData() {
                getData();
            }
        }));

    const [totalRows, SetTotalRows] = React.useState<number>(0);
    const [currentPage, SetCurrentPage] = React.useState<number>(1);
    const [sizePerPage, SetSizePerPage] = React.useState<number>(25);
    const [selectedRow, setSelectedRow] = React.useState<TableRowId>();
    const [showDispatcherJobDetailsModal, setShowDispatcherJobDetailsModal] = React.useState<boolean>(false);
    const [selectedDispatcherJobId, setSelectedDispatcherJobId] = React.useState<number>(undefined);
    const [selectedFilterByOption, setSelectedFilterByOption] = React.useState<number>(1);
    const [filterValue, setFilterValue] = React.useState<string>("");
    const [processingStatusSelected, setProcessingStatusSelected] = React.useState<RowProcessingStatus | number>(-1);
    const [sortedInputParametersById, setSortedInputParametersById] = React.useState<InputParameterResultModel[]>([]);
    const [sortedOutputParmetersById, setSortedOutputParametersById] = React.useState<OutputParameterResultModel[]>([]);
    const [filterParameter, setFilterParameter] = React.useState<IFilterParamData>(undefined);
    const [showAddExperimentRowsModal, setShowAddExperimentRowsModal] = React.useState<boolean>(false);
    const noDataIntervalRef = React.useRef<number>();

    const [sortState, setSortState] = React.useState<{ sortColumn: string; sortDirection: "ascending" | "descending"; }>
        ({
            sortColumn: "id",
            sortDirection: "ascending",
        });


    React.useEffect(() => {

        return () => {
            if (!!noDataIntervalRef.current)
                window.clearInterval(noDataIntervalRef.current);
        }
    }, []);

    React.useEffect(() => {
        getData();
    }, [currentPage]);


    React.useEffect(() => {
        const sortedInputParams = _copyAndSort<InputParameterResultModel>(props.selectedScenario.inputParameters, "filterParameterId", false);
        setSortedInputParametersById(sortedInputParams);
    }, [props.selectedScenario.inputParameters]);

    React.useEffect(() => {
        const sortedOutputParams = _copyAndSort<OutputParameterResultModel>(props.selectedScenario.outputParameters, "filterParameterId", false);
        setSortedOutputParametersById(sortedOutputParams);
    }, [props.selectedScenario.outputParameters]);


    const onColumnClick = (columnId: string) => {

        if (sortState.sortColumn != columnId) {
            setSortState({
                sortColumn: columnId,
                sortDirection: "ascending"
            })
        } else {
            if (sortState.sortDirection == "ascending") {
                setSortState({ sortColumn: columnId, sortDirection: "descending" });
            } else {
                setSortState({ sortColumn: columnId, sortDirection: "ascending" });
            }
        }
    }


    const getInputParameterIndex = (paramId: number) => {
        return sortedInputParametersById.findIndex(x => x.filterParameterId == paramId);
    }
    const getOutputParameterIndex = (paramId: number) => {
        return sortedOutputParmetersById.findIndex(x => x.filterParameterId == paramId);
    }
    const getParameterIndex = (parameterId: string) => {
        const paramSplit = parameterId.split('_');
        if (paramSplit && paramSplit.length > 0) {
            if (paramSplit[0] == "in") {
                const index = getInputParameterIndex(+paramSplit[1]);
                return `in_${index}.value`;
            }
            if (paramSplit[0] == "out") {
                const index = getOutputParameterIndex(+paramSplit[1]);
                return `out_${index}.value`;
            }
        }
        return -1;
    }
    // Get data query
    const { data: rows, refetch: getData, isLoading: isLoadingRows, isFetching: isFetchingRows } = useQuery({
        queryKey: ['excel-runner-data', props.selectedScenario.id, currentPage, sortState.sortColumn, sortState.sortDirection],
        initialData: [],
        refetchInterval: false,
        queryFn: async () => {
            try {
                const client = getScenariosClient();
                let filterParamData: ParameterFilter = undefined;
                if (filterParameter) {
                    filterParamData = new ParameterFilter();
                    filterParamData.parameterId = filterParameter.parameterId ? getParameterIndex(filterParameter.parameterId).toString() : undefined;
                    filterParamData.filterOperation = filterParameter.operation;
                    filterParamData.equalsValue = filterParameter.equalsValue ? +filterParameter.equalsValue : undefined;
                    filterParamData.endsValue = filterParameter.endValue ? +filterParameter.endValue : undefined;
                }

                const rowNumber = selectedFilterByOption == 1 ? (filterValue.length > 0 ? Number(filterValue) : undefined) : undefined;
                const rowId = selectedFilterByOption == 2 ? (filterValue.length > 0 ? Number(filterValue) : undefined) : undefined;
                const query = {
                    rowNumber: rowNumber,
                    rowId: rowId,
                    scenarioId: props.selectedScenario.id,
                    processingStatus: processingStatusSelected > -1 ? processingStatusSelected : undefined,
                    parameterFilter: filterParamData,
                    orderBy: sortState.sortColumn.toString(),
                    orderByDescending: sortState.sortDirection == "descending",
                    skip: (currentPage - 1) * sizePerPage,
                    take: sizePerPage
                } as ISenarioDataElasticQuery;

                const dataResult = await client.getScenarioDataElastic(new SenarioDataElasticQuery(query));

                if (dataResult.totalRecords == 0) {
                    if (!(noDataIntervalRef?.current))
                        noDataIntervalRef.current = window.setInterval(() => {
                            getData();
                        }, 5000);

                } else {
                    if (!!noDataIntervalRef.current)
                        window.clearInterval(noDataIntervalRef.current);
                }
                SetTotalRows(dataResult.totalRecords);
                return dataResult.rows;
            }
            catch (error) {
                console.log("An error occurred when trying to get scenario data.", error);
            }
        }
    });



    const resubmitToDispatcher = async (filerow: FileRowResultModel) => {
        const messageId = props.loadingService.showMessage("Resubmitting  to dispatcher...");
        try {
            const accessToken: string = await getUserToken();

            const client = getScenariosClient();
            var model = {
                accessToken: accessToken,
                scenarioId: +props.selectedScenario.id,
                rowId: filerow.id
            } as ResubmitRowPostModel;
            await client.resubmitRow(model);
            toast.success(`Row with id ${filerow.id} was resubmitted.`);
            getData();

        } catch (error) {
            toast.error("An error occurred while trying to resubmit to dispatcher.");
            console.log("An error occurred while trying to resubmit to dispatcher.", error);
        } finally {
            props.loadingService.hideMessage(messageId);
        }
    }

    const onDispatcherIdClick = (dispatcherJobId: number) => {
        setSelectedDispatcherJobId(dispatcherJobId);
        setShowDispatcherJobDetailsModal(true);
    }


    const resetFilters = () => {
        setSelectedFilterByOption(1);
        setFilterValue("");
        setProcessingStatusSelected(-1);
        setFilterParameter(undefined);
        setSortState(() => ({ sortColumn: "id", sortDirection: "ascending" }));
    }
    const processingStatusOptions: IDropdownOption[] = [
        { key: -1, text: "All" },
        { key: RowProcessingStatus.Calculated, text: "Converged" },
        { key: RowProcessingStatus.CalculationFailed, text: "Not Converged" },
        { key: RowProcessingStatus.SubmittedToDispatcher, text: "Submitted to dispatcher" },
        { key: RowProcessingStatus.NotSubmittedToDispatcher, text: "Not submitted to dispatcher" },
        { key: RowProcessingStatus.ProcessingFailed, text: "Processing failed" }
    ];

    const getContextItems = (row: FileRowResultModel) => {
        let menuItems: JSX.Element[] = [];
        menuItems.push(
            <MenuItem
                disabled={!!props.selectedScenario?.isDeleted}
                key="details"
                onClick={() => { resubmitToDispatcher(row); }} > Resubmit to dispatcher</MenuItem>);

        return menuItems;
    }

    const getInputParamColumns = () => {
        let incolumnParams = props.filter.inputFilterParameters;
        if (incolumnParams) {
            incolumnParams = _copyAndSort<InputFilterParameterResultModel>(incolumnParams, "order", false);
        }

        const inputParamColumns = incolumnParams.map((filterParam, paramIndex) => {

            return createTableColumn<FileRowResultModel>({
                columnId: `parameters.in_${paramIndex}.value`,
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                        <ParameterDisplay
                            value={filterParam.name}
                            fileUniqueId={props.filter.fileUniqueIdentifier}
                            versionNumber={props.filter.fileVersionNumber.toString()}
                        />
                    </TableCellLayout>
                },
                renderCell: (item) => {                   
                    const cell = item.cells.find((value) => value.filterParameterId == filterParam.id);
                    return <TableCellLayout style={{ justifyContent: 'center' }}>
                        {!!cell && <SignificantFigures value={+cell.value} significantFigures={5} spanStyle={{ width: "100%", textAlign: "center" }} />}
                    </TableCellLayout>
                },
            })

        });
        return inputParamColumns;
    }


    const getOutputParamColumns = () => {
        let outcolumnParams = props.filter.outputFilterParameters;
        if (outcolumnParams) {
            outcolumnParams = _copyAndSort<InputFilterParameterResultModel>(outcolumnParams, "order", false);
        }
        const outputParamColumns = outcolumnParams.map((filterParam, paramIndex) => {
            const studyParam = props.selectedScenario.outputParameters.find(x => x.filterParameterId == filterParam.id);
            return createTableColumn<FileRowResultModel>({
                columnId: `parameters.out_${paramIndex}.value`,
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                        <ParameterDisplay
                            value={filterParam.name}
                            fileUniqueId={props.filter.fileUniqueIdentifier}
                            versionNumber={props.filter.fileVersionNumber.toString()}
                        />
                    </TableCellLayout>
                },
                renderCell: (item) => {

                    const cell = item.results?.find((value) => value.filterParameterId == filterParam.id);
                    return <TableCellLayout style={{ justifyContent: 'center' }}>
                        {!cell ? "-" : <SignificantFigures value={+cell.value}
                            significantFigures={5}
                            spanStyle={{ width: "100%", textAlign: "center" }} />}
                    </TableCellLayout>
                },
            });

        });

        return outputParamColumns;

    }

    const getColumns = () => {
        return [
            createTableColumn<FileRowResultModel>({
                columnId: "id",
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ justifyContent: 'center', fontWeight: tokens.fontWeightSemibold }}>Row #<br />Row ID</TableCellLayout>;
                },
                renderCell: (item) => {
                    return <TableCellLayout style={{ justifyContent: 'center', textAlign: "center" }}>

                        <span>{item.rowNum}</span>
                        <br />
                        <span style={{ color: "#d0cece" }}>{item.id}</span>

                    </TableCellLayout>;
                },
            }),
            createTableColumn<FileRowResultModel>({
                columnId: "processingStatus",
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ justifyContent: 'center', fontWeight: tokens.fontWeightSemibold }}>Processing Status</TableCellLayout>;
                },
                renderCell: (item) => {
                    return <TableCellLayout style={{ justifyContent: 'center', textAlign: "center" }}>
                        <ExcelRunnerRowProcessingStatus row={item} /><br />
                        {item.dispatcherJobId ?
                            <span className="job-details-link" style={{ cursor: "pointer" }}
                                onClick={() => { onDispatcherIdClick(item.dispatcherJobId) }}>
                                #{item.dispatcherJobId}</span>
                            : ""}
                    </TableCellLayout>
                },
            }),

            ...getInputParamColumns(),

            ...getOutputParamColumns()
        ];
    }



    const contextMenuRef = React.createRef<ContextMenuWithDataType>();

    const columns = React.useMemo<TableColumnDefinition<FileRowResultModel>[]>(() => getColumns(), []);

    const getHeaderCellBackground = (columnId: string) => {
        if (columnId) {
            if (columnId.startsWith('parameters.in'))
                return tokens.colorPaletteDarkOrangeBackground2;
            else if (columnId.startsWith('parameters.out'))
                return tokens.colorPaletteGreenBackground2;
        }

        return null;
    }
    const onSearchClick = () => {
        //   console.log("onSearchClick", currentPage);
        if (currentPage == 1) {
            getData();
        } else {
            SetCurrentPage(1);
        }
    }


    const filterRowOptions: IDropdownOption[] = [{ key: 1, text: 'Row Number' }, { key: 2, text: 'Row ID' }];

    const totalPages = totalRows > sizePerPage ? Math.ceil(totalRows / sizePerPage) : 1;
    const selectedFilterRowOption = filterRowOptions.find(x => x.key == selectedFilterByOption);
    const processingStatusSelectedOption = processingStatusOptions.find(x => x.key == processingStatusSelected);
    return <div>
        {!!props.selectedScenario && props.selectedScenario.processingStatus !== ProcessingStatus.NotRunning &&
            <Button appearance='primary' onClick={() => { setShowAddExperimentRowsModal(true); }} >Add data</Button>
        }
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: "10px" }}>
                <span style={{ marginRight: "10px" }}>Filter by:</span>
                <div style={{ display: "flex" }}>
                    <Dropdown
                        style={{ marginRight: "var(--spacingHorizontalXS)" }}
                        value={selectedFilterRowOption.text}
                        onOptionSelect={(ev, data) => setSelectedFilterByOption(+data.optionValue)}
                    >
                        {filterRowOptions.map(option => (
                            <Option key={option.key} value={option.key.toString()} >
                                {option.text}
                            </Option>
                        ))}
                    </Dropdown>
                    <Input value={filterValue} type="number" onChange={(ev, data) => { setFilterValue(data.value); }} />
                </div>

            </div>
            <div style={{ display: "flex", flexDirection: "column", marginRight: "10px" }}>
                <span >Processing status:</span>
                <Dropdown
                    value={processingStatusSelectedOption.text}
                    onOptionSelect={(ev, data) => setProcessingStatusSelected(+data.optionValue)}
                >
                    {processingStatusOptions.map(option => (
                        <Option key={option.key} value={option.key.toString()} >
                            {option.text}
                        </Option>
                    ))}
                </Dropdown>

            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <FilterParameterPicker
                    value={filterParameter}
                    selectedScenario={props.selectedScenario}
                    selectedFilter={props.filter}
                    onChange={(data) => { setFilterParameter(data); console.log("Filter parameter data changed ", data); }} />
            </div>

            <Button appearance='primary' style={{ width: "100px", alignSelf: "flex-end" }} onClick={() => onSearchClick()}>Search</Button>
            <Button icon={<DismissRegular />} appearance="subtle" style={{ marginLeft: "10px", alignSelf: "end", backgroundColor: "rgb(237, 235, 233)" }} onClick={resetFilters} />

        </div>
        <DataGridScrollableWrapper columnsCount={columns.length} columnWidth={150}>
            <DataGrid
                items={rows}
                columns={columns}
                selectedItems={selectedRow ? [selectedRow] : []}
                sortable
                sortState={sortState}
                subtleSelection
                selectionMode="single"
            >
                <DataGridHeader style={{ ...DataGridScrollableHeaderStyles }}>
                    <DataGridRow selectionCell={null} style={{ alignItems: 'stretch' }}>
                        {({ renderHeaderCell, columnId }) => (
                            <DataGridHeaderCell
                                style={{ backgroundColor: getHeaderCellBackground(columnId.toString()), cursor: "pointer" }}
                                sortDirection={sortState.sortColumn == columnId ? sortState.sortDirection : undefined}
                                onClick={() => onColumnClick(columnId.toString())}>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>

                {(isLoadingRows || isFetchingRows) &&
                    <Skeleton>
                        {[...Array(20)].map(() => <SkeletonItem size={40} style={{ marginTop: '4px' }} />)}
                    </Skeleton>
                }

                {!isLoadingRows && !isFetchingRows && rows.length > 0 &&
                    <DataGridBody<FileRowResultModel>>
                        {({ item, rowId }) => (
                            <DataGridRow<FileRowResultModel>
                                key={rowId}
                                selectionCell={null}
                                onContextMenu={(ev) => {
                                    ev.preventDefault();
                                    setSelectedRow(rowId);
                                    contextMenuRef.current?.showMenu(ev as any, item);
                                }}
                            >
                                {({ renderCell }) => (
                                    <DataGridCell>{renderCell(item)}</DataGridCell>
                                )}

                            </DataGridRow>
                        )}
                    </DataGridBody>}

                {!isLoadingRows && !isFetchingRows && rows.length == 0 &&
                    <TableBody>
                        <TableRow

                            key={"no- data"}
                            onContextMenu={(ev) => {
                                ev.preventDefault();
                            }}
                        >
                            <TableCell colSpan={columns.length} style={{ justifyContent: "center" }}><span>Data will be available in few seconds.</span></TableCell>
                        </TableRow>
                    </TableBody>

                }

            </DataGrid>
        </DataGridScrollableWrapper>

        <ContextMenuWithData ref={contextMenuRef} >
            {(data) => {
                console.log("Rendering context menu items.", data);
                return getContextItems(data);
            }}
        </ContextMenuWithData>
        <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me page-item'}
            breakLinkClassName={'page-link'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            forcePage={currentPage - 1}
            onPageChange={(page) => { console.log("Selected page:", page); SetCurrentPage(page.selected + 1); }}
            containerClassName={'pagination'}
            activeClassName={'active'}
        />

        {showDispatcherJobDetailsModal && selectedDispatcherJobId &&
            <DispatcherJobDetails
                isExample={props.isExample}
                jobId={selectedDispatcherJobId}
                isOpened={showDispatcherJobDetailsModal}
                onClose={() => setShowDispatcherJobDetailsModal(false)}
            />}

        {showAddExperimentRowsModal && props.selectedScenario && props.filter &&
            <AddExperimentRowsModal
                experimentId={props.selectedScenario.id}
                experiment={{ ...props.selectedScenario, inputParameters: [], outputParameters: [] } as INewExperiment}
                filter={props.filter}
                isExample={props.isExample}
                onClose={() => { setShowAddExperimentRowsModal(false); }}
                onSuccess={() => { setShowAddExperimentRowsModal(false); setTimeout(() => { getData(); }, 1000); }}
                isOpened={showAddExperimentRowsModal}

            />}

    </div>;
});

export default DataTab;
