import React from "react";
import ReactSelect, { ActionMeta, components, GroupBase, InputActionMeta, MultiValue, StylesConfig } from "react-select";
import Select from "react-select/dist/declarations/src/Select";
import { getFilesClient } from "../../services/dashboard.service";
import { FileSortFields, FileSystemEntityType, SearchFileModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import useDebounce from "../../hooks/useDebounce";
import { Button } from "@fluentui/react-components";
import { SearchRegular } from "@fluentui/react-icons";
import styles from "../../components/paramater-picker-next/parameter-picker-next.style.scss";


type SearchFilesAutoSuggestProps = {
    inputValue?: string;
    parentDirectoryId?: string;
    onInputChange: (term?: string) => void;
    onChange?: (term?: string) => void;
    onSearchClick?: (term?: string) => void;
}

export const SearchFilesAutoSuggest: React.FC<SearchFilesAutoSuggestProps> = (props) => {
    const selectRef = React.createRef<Select<SelectOption, true, GroupBase<SelectOption>>>();
    const [currentValue, setCurrentValue] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<SelectGroupOption[]>([]);
    const debouncedValue = useDebounce(currentValue, 500);

    React.useEffect(() => {
        setCurrentValue(props.inputValue ?? "");

    }, [props.inputValue]);

    React.useEffect(() => {
        if (!!debouncedValue && debouncedValue.length > 2) {
            updateSuggestions();
        } else {
            setOptions([]);
        }

    }, [debouncedValue]);

    const updateSuggestions = async () => {
        try {
            setIsLoading(true);
            const client = getFilesClient();
            const searchResp = await client.searchFiles(debouncedValue, props.parentDirectoryId, FileSortFields.Name, false);
            const files = searchResp.filter(x => x.type == FileSystemEntityType.File);
            const folders = searchResp.filter(x => x.type == FileSystemEntityType.Directory);
            const fileSuggestions = getSuggestions(files, folders, 5);
            const folderSuggestions = getSuggestions(folders, files, 5);
            let result: SelectGroupOption[] = [];
            if (!!folderSuggestions && folderSuggestions.length > 0) {
                result.push({
                    label: "Folders",
                    options: folderSuggestions?.map(x => ({ label: x.name, value: x.name } as SelectOption))
                })
            }
            if (!!fileSuggestions && fileSuggestions.length > 0) {
                result.push({
                    label: "Files",
                    options: fileSuggestions?.map(x => ({ label: x.name, value: x.name } as SelectOption))
                })
            }
            setOptions(result);


        } catch (error) {

        } finally {
            setIsLoading(false);
        }

    }

    const onSelectionChange = (selectedOption: MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
        console.log("onSelectionChange", selectedOption, actionMeta);
        if (actionMeta.action == "select-option") {
            // console.log("selectedOptions", selectedOption);
            const newValue = (selectedOption as any as SelectOption);
            props.onChange?.(newValue.value.toString());
        }

        if (actionMeta.action == "remove-value" || actionMeta.action == "clear" || actionMeta.action == "deselect-option") {

            props.onChange(undefined);
        }
    }


    return <div style={{ width: "100%", maxWidth: "400px", display: "flex", padding: "4px" }}>
        <ReactSelect
            ref={selectRef}
            menuPlacement={"auto"}
            placeholder="Search"
            styles={ParameterPickerStyless}
            isLoading={isLoading}
            isClearable={true}
            options={options}
            value={[]}
            inputValue={props.inputValue ?? ""}
            noOptionsMessage={() => <span>No suggestions found.</span>}
            onMenuClose={() => { console.log("On menu close."); }}
            onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
                console.log("onInputChange", newValue, actionMeta);
                if (actionMeta.action == "input-blur" || actionMeta.action == "menu-close") {
                    return;
                }

                props.onInputChange(newValue);
            }}
            onChange={onSelectionChange}
            components={{ Input: CustomInput }}
        />
        <Button icon={<SearchRegular />} appearance="transparent" onClick={() => { props.onSearchClick(); }}></Button>
    </div>


}

type SelectOption = {
    value: string | number,
    label: string

}

export type SelectGroupOption = {
    readonly label: string;
    readonly options: SelectOption[];
}


function getSuggestions(arr1: SearchFileModel[], arr2: SearchFileModel[], take: number) {
    let needsToTake = take;
    if (arr1.length < needsToTake) {
        needsToTake = arr1.length;
    } else {
        if (arr2.length - take < 0) {
            needsToTake += arr2.length - take;
        }
    }
    const canTake = Math.min(arr1.length, needsToTake);
    let result = arr1.slice(0, canTake);



    return result;
}
// Create a custom Input component
const CustomInput = (props) => {
    return (
        <components.Input
            {...props}
            // Apply your custom styles directly to the input element
            style={{
                color: 'inherit',
                background: '0px center',
                opacity: 1,         // Make input transparent
                width: '100%',      // Full width
                gridArea: '1 / 2',  // Align input
                font: 'inherit',
                minWidth: '2px',    // Small minimum width
                border: '0px',      // Remove borders
                margin: '0px',
                outline: '0px',     // Remove outline
                padding: '0px'      // Remove padding
            }}
        />
    );
};

const ParameterPickerStyless: StylesConfig<SelectOption, true, GroupBase<SelectOption>> = {
    container: (styles) => ({ ...styles, flex: 1 }),
    control: (styles) => ({ ...styles, minHeight: "30px" }),
    dropdownIndicator: (styles) => ({
        ...styles,
        paddingTop: 5,
        paddingBottom: 5,
    }),
    clearIndicator: (styles) => ({
        ...styles,
        paddingTop: 5,
        paddingBottom: 5,
    }),
    input: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
        opacity: "1 !important"
    }),
    indicatorSeparator: (base) => ({ ...base, display: "none" }),
    multiValueLabel: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: "14px"
    }),
    option: (styles) => ({
        ...styles,
        paddingTop: "4px",
        paddingBottom: "4px",
        lineHeight: "16px"
    }),
};