import { Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridProps, DataGridRow, Input, MenuItem, Select, TableCellLayout, TableColumnDefinition, TableRowId, Tooltip, createTableColumn, tokens } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React, { useMemo } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DispatcherJobDetails } from "../../../components/dispatcher-job-details/dispatcher-job-details.component";
import { ParameterDisplay } from "../../../components/parameter-display/parameter-display.component";
import { ContextMenu, ContextMenuType, ContextMenuWithData, ContextMenuWithDataType } from "../../../files/context-menu/context-menu.component";
import { getSensitivityStudiesClient } from "../../../services/sensitivity-studies.service";
import { FileModel, FilterResultModel, InputFilterParameterResultModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { _copyAndSort } from "../../../utils/helpers/array.helpers";
import { getSignificantFigures } from "../../../utils/helpers/significant-figures";
import { IDropdownOption } from "../../../utils/shared.types";
import { ParameterFilter, RowModel, RowProcessingStatus, SensitivityStudyDataQuery, SensitivityStudyInputParameterPostModel, SensitivityStudyOutputParameterPostModel, SensitivityStudyResultModel, StudyType } from "../../../swagger-clients/sensitivity-studies-api-clients.service";
import { SensitivityStudiesRouteParams } from "../../sensitivity-studies-list.component";
import FilterParameterPicker, { IFilterParamData } from "./filter-parameter-picker.component";
import "./sensitivity-study-data.styless.scss";
import { SensitivityStudyRowProcessingStatus } from "./sensitivity-study-row-processing-status/sensitivity-study-row-processing-status.component";
import { SignificantFigures } from "../../../components/significant-figures/significant-figures.component";
import { DataGridScrollableHeaderStyles, DataGridScrollableWrapper } from "../../../utils/DataGridScrollableWrapper";
import { Skeleton, SkeletonItem } from "@fluentui/react-components";
import { useQuery } from "@tanstack/react-query";

declare const chrome: any;

type SensivityStudyDataProps = {
    isOptimumTester: boolean;
    isDwsimApp?: boolean;
    isExample?: boolean;
    baseOptReactRoute?: string;
    selectedStudy: SensitivityStudyResultModel;
    selectedFile: FileModel;
    filter: FilterResultModel;
    isLoading: boolean;
    onResubmitToDispatcherClick: (fileRow: RowModel) => void;
};


export type SensivityStudyDataType = { reloadData(): void; };

export const SensivityStudyData = React.forwardRef<SensivityStudyDataType, SensivityStudyDataProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            reloadData() {
                getData();
            }
        }));

    const routeParams = useParams<SensitivityStudiesRouteParams>();
    const [totalRows, setTotalRows] = React.useState<number>(0);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [sizePerPage, setSizePerPage] = React.useState<number>(100);
    const [showDispatcherJobDetailsModal, setShowDispatcherJobDetailsModal] = React.useState<boolean>(false);
    const [selectedDispatcherJobId, setSelectedDispatcherJobId] = React.useState<number>(undefined);

    const sortedInputParametersById = React.useMemo<SensitivityStudyInputParameterPostModel[]>(() => {
        const sotred = _copyAndSort<SensitivityStudyInputParameterPostModel>(props.selectedStudy.sensitivityStudyInputParameters, "id", false);
        return sotred;
    }, [props.selectedStudy?.sensitivityStudyInputParameters]);

    const sortedOutputParmetersById = React.useMemo<SensitivityStudyOutputParameterPostModel[]>(() => {
        const sorted = _copyAndSort<SensitivityStudyOutputParameterPostModel>(props.selectedStudy.sensitivityStudyOutputParameters, "id", false);
        return sorted;
    }, [props.selectedStudy.sensitivityStudyOutputParameters]);

    // search
    const [idOptionSelected, setIdOptionSelected] = React.useState<number>(0);
    const [idValue, setIdValue] = React.useState<string>("");
    const [processingStatusSelected, setProcessingStatusSelected] = React.useState<RowProcessingStatus>(-1 as any);
    const [filterParameter, setFilterParameter] = React.useState<IFilterParamData>(undefined);

    const [sortState, setSortState] = React.useState<{ sortColumn: string; sortDirection: "ascending" | "descending"; }>
        ({
            sortColumn: "id",
            sortDirection: "ascending",
        });


    const [selectedRow, setSelectedRow] = React.useState<TableRowId>();
    const contextMenuRef = React.createRef<ContextMenuWithDataType>();

    const onColumnClick = (columnId: string) => {

        if (sortState.sortColumn != columnId) {
            setSortState({
                sortColumn: columnId,
                sortDirection: "ascending"
            })
        } else {
            if (sortState.sortDirection == "ascending") {
                setSortState({ sortColumn: columnId, sortDirection: "descending" });
            } else {
                setSortState({ sortColumn: columnId, sortDirection: "ascending" });
            }
        }
    }

    // Get data query
    const { data: rows, refetch: getData, isLoading: isLoadingRows, isFetching: isFetchingRows } = useQuery({
        queryKey: ['sensitivity-study-data', props.selectedStudy.id, currentPage, sortState.sortColumn, sortState.sortDirection],
        initialData: [],
        queryFn: async () => {
            console.log("GetData new version.");
            try {
                const client = getSensitivityStudiesClient();

                let filterParamData: ParameterFilter = undefined;
                if (filterParameter) {
                    filterParamData = new ParameterFilter();
                    filterParamData.parameterId = filterParameter.parameterId ? getParameterIndex(filterParameter.parameterId).toString() : undefined;
                    filterParamData.filterOperation = filterParameter.operation;
                    filterParamData.equalsValue = filterParameter.equalsValue ? +filterParameter.equalsValue : undefined;
                    filterParamData.endsValue = filterParameter.endValue ? +filterParameter.endValue : undefined;
                }

                const query = new SensitivityStudyDataQuery({
                    rowId: idValue.length > 0 && idOptionSelected == 0 ? Number(idValue) : undefined,
                    studyType: props.isOptimumTester ? StudyType.OptimumTest : StudyType.SensitivityStudy,
                    dispatcherJobId: idValue.length > 0 && idOptionSelected == 1 ? Number(idValue) : undefined,
                    sensitivityStudyId: props.selectedStudy.id,
                    processingStatus: processingStatusSelected > -1 ? processingStatusSelected : undefined,
                    parameterFilter: filterParamData,
                    sensitivityStudyVersionId: props.selectedStudy.currentVersionId,
                    orderBy: sortState.sortColumn.toString(),
                    orderByDescending: sortState.sortDirection == "descending",
                    skip: (currentPage - 1) * sizePerPage,
                    take: sizePerPage
                });

                const dataResult = await client.getSensitivityStudyDataElastic(query);

                setTotalRows(dataResult.totalRecords);
                return dataResult.rows;
            }
            catch (error) {
                console.log("An error occurred when trying to get scenario data.", error);
            }
        }
    });

    const getInputParameterIndex = (paramId: number) => {
        return sortedInputParametersById.findIndex(x => x.id == paramId);
    }

    const getOutputParameterIndex = (paramId: number) => {
        return sortedOutputParmetersById.findIndex(x => x.id == paramId);
    }
    const getParameterIndex = (parameterId: string) => {
        const paramSplit = parameterId.split('_');
        if (paramSplit && paramSplit.length > 0) {
            if (paramSplit[0] == "in") {
                const index = getInputParameterIndex(+paramSplit[1]);
                return `in_${index}.value`;
            }
            if (paramSplit[0] == "out") {
                const index = getOutputParameterIndex(+paramSplit[1]);
                return `out_${index}.value`;
            }
        }
        return -1;
    }

    const onDispatcherIdClick = (dispatcherJobId: number) => {
        setSelectedDispatcherJobId(dispatcherJobId);
        setShowDispatcherJobDetailsModal(true);
    }

    const onTestForOptimumClick = (filerow: RowModel) => {
        let url = `${window.location.origin}/files/${routeParams.uniquefileId}/optimum-tester/edit?cloneId=${routeParams.studyId}&initialrowId=${filerow.id}`;
        if (props.isDwsimApp) {
            url = `https://dwsim.webui.local/index.html#${props.baseOptReactRoute}/files/${routeParams.uniquefileId}/optimum-tester/edit?cloneId=${routeParams.studyId}&initialrowId=${filerow.id}`;

        }
        window.location.href = url;

    }

    const resetFilters = () => {
        setIdOptionSelected(0);
        setIdValue("");
        setProcessingStatusSelected(-1 as any);
        setFilterParameter(null);
        setSortState(() => ({ sortColumn: "id", sortDirection: "ascending" }));
    }

    const getContextItems = (row: RowModel) => {
        let menuItems: JSX.Element[] = [];

        menuItems.push(
            <MenuItem key="resubmit"
                disabled={!!props.selectedStudy.isDeleted}
                onClick={() => { props.onResubmitToDispatcherClick(row); }}> Resubmit to dispatcher</MenuItem>);
        if (!props.isOptimumTester) {
            menuItems.push(<MenuItem key="testForOptimum" onClick={() => { onTestForOptimumClick(row); }}> Test for Optimum</MenuItem>);
        }

        return menuItems;
    }


    const totalPages = totalRows > sizePerPage ? Math.ceil(totalRows / sizePerPage) : 1;
    const idOptions: IDropdownOption[] = [
        { key: 0, text: "Row ID" },
        { key: 1, text: "Dispatcher job ID" }
    ];

    const processingStatusOptions: IDropdownOption[] = [
        { key: -1, text: "All" },
        { key: RowProcessingStatus.Calculated, text: "Converged" },
        { key: RowProcessingStatus.CalculationFailed, text: "Not Converged" },
        { key: RowProcessingStatus.SubmittedToDispatcher, text: "Submitted to dispatcher" },
        { key: RowProcessingStatus.NotSubmittedToDispatcher, text: "Not submitted to dispatcher" },
        { key: RowProcessingStatus.OutputConstraintViolated, text: "Output constraint violation" },
        { key: RowProcessingStatus.PostProcessingFailed, text: "Post processing failed" },
        { key: RowProcessingStatus.ProcessingFailed, text: "Processing failed" },
        { key: RowProcessingStatus.ReceivedResults, text: "Recieved results" }
    ];

    const getColumns = () => {
        return [
            createTableColumn<RowModel>({
                columnId: "id",
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ justifyContent: 'center', fontWeight: tokens.fontWeightSemibold }}>Row ID</TableCellLayout>;
                },
                renderCell: (item) => {
                    return <TableCellLayout style={{ justifyContent: 'center' }}>{item.id}</TableCellLayout>;
                },
            }),
            createTableColumn<RowModel>({
                columnId: "processingStatus",
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ justifyContent: 'center', fontWeight: tokens.fontWeightSemibold }}>Processing Status</TableCellLayout>;
                },
                renderCell: (item) => {
                    return <TableCellLayout style={{ justifyContent: 'center' }}>
                        <SensitivityStudyRowProcessingStatus row={item} showError={true} /><br />
                        {item.dispatcherJobId ?
                            <span className="job-details-link" style={{ cursor: "pointer" }}
                                onClick={() => { onDispatcherIdClick(item.dispatcherJobId) }}>
                                #{item.dispatcherJobId}</span>
                            : ""}
                    </TableCellLayout>
                },
            }),
            createTableColumn<RowModel>({
                columnId: "evaluationFunctionValue",
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ justifyContent: 'center', fontWeight: tokens.fontWeightSemibold }}>Evaluation function</TableCellLayout>;
                },
                renderCell: (item) => {
                    return <TableCellLayout style={{ justifyContent: 'center' }}>{!item.evaluationFunctionValue ? "-" :
                        <Tooltip
                            relationship="label"
                            content={item.evaluationFunctionValue.toString()}>
                            <span>{getSignificantFigures(+item.evaluationFunctionValue, 5)}</span>
                        </Tooltip>
                    }</TableCellLayout>
                },
            }),

            ...getInputParamColumns(),

            ...getOutputParamColumns()
        ];
    }

    const getInputParamColumns = () => {


        let incolumnParams = props.filter.inputFilterParameters;
        if (incolumnParams) {
            incolumnParams = _copyAndSort<InputFilterParameterResultModel>(incolumnParams, "order", false);
        }

        const inputParamColumns = incolumnParams.map((filterParam) => {

            const studyParam = props.selectedStudy.sensitivityStudyInputParameters.find(x => x.inputFilterParameterId == filterParam.id);
            const paramIndex = getInputParameterIndex(studyParam.id);

            return createTableColumn<RowModel>({
                columnId: `parameters.in_${paramIndex}.value`,
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                        <ParameterDisplay
                            value={filterParam.name}
                            fileUniqueId={props.selectedFile.uniqueIdentifier}
                            versionNumber={props.selectedFile.currentVersionNumber.toString()}
                        />
                    </TableCellLayout>
                },
                renderCell: (item) => {
                    const cell = item.inputParameters.find((value) => value.sensitivityStudyInputParameter.inputFilterParameterId == studyParam.inputFilterParameterId);
                    return <TableCellLayout style={{ justifyContent: 'center' }}><SignificantFigures value={+cell.value} significantFigures={5} spanStyle={{ width: "100%", textAlign: "center" }} /></TableCellLayout>
                },
            })

        });

        return inputParamColumns;

    }

    const getOutputParamColumns = () => {
        let outcolumnParams = props.filter.outputFilterParameters;
        if (outcolumnParams) {
            outcolumnParams = _copyAndSort<InputFilterParameterResultModel>(outcolumnParams, "order", false);
        }
        const outputParamColumns = outcolumnParams.map((filterParam) => {
            const studyParam = props.selectedStudy.sensitivityStudyOutputParameters.find(x => x.outputFilterParameterId == filterParam.id);
            const paramIndex = getOutputParameterIndex(studyParam.id);

            return createTableColumn<RowModel>({
                columnId: `parameters.out_${paramIndex}.value`,
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                        <ParameterDisplay
                            value={filterParam.name}
                            fileUniqueId={props.selectedFile.uniqueIdentifier}
                            versionNumber={props.selectedFile.currentVersionNumber.toString()}
                        />
                    </TableCellLayout>
                },
                renderCell: (item) => {

                    const cell = item.results?.find((value) => value.sensitivityStudyOutputParameter.outputFilterParameterId == studyParam.outputFilterParameterId);
                    return <TableCellLayout style={{ justifyContent: 'center' }}>{!cell ? "-" : <SignificantFigures value={+cell.value} significantFigures={5} spanStyle={{ width: "100%", textAlign: "center" }} />}</TableCellLayout>
                },
            });
        });
        return outputParamColumns;
    }

    const columns = useMemo<TableColumnDefinition<RowModel>[]>(() => getColumns(), []);

    const getHeaderCellBackground = (columnId: string) => {
        if (columnId) {
            if (columnId.startsWith('parameters.in'))
                return tokens.colorPaletteDarkOrangeBackground2;
            else if (columnId.startsWith('parameters.out'))
                return tokens.colorPaletteGreenBackground2;
        }

        return null;
    }

    const onSearchClick = () => {
        // console.log("onSearchClick", currentPage);
        if (currentPage == 1) {
            getData();
        } else {
            setCurrentPage(1);
        }
    }

    return <div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginRight: "10px" }}>ID:</span>
                <div style={{ display: "flex" }}>
                    <Select
                        style={{ minWidth: "150px" }}
                        value={idOptionSelected}
                        onChange={(ev, data) => { setIdOptionSelected(+data.value); }}>
                        {idOptions.map((option) => {
                            return <option value={option.key?.toString()}>{option.text}</option>
                        })}
                    </Select>

                    <Input
                        value={idValue}
                        type="number"
                        onChange={(ev, data) => { setIdValue(data.value); }}
                        style={{ minWidth: "150px", marginRight: "10px" }} />
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginRight: "10px" }}>
                <span style={{ marginRight: "10px" }}>Processing status:</span>
                <Select
                    style={{ minWidth: "220px" }}
                    value={processingStatusSelected}
                    onChange={(ev, data) => { setProcessingStatusSelected(+data.value); }}>
                    {processingStatusOptions.map((option) => {
                        return <option value={option.key?.toString()}>{option.text}</option>
                    })}
                </Select>

            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <FilterParameterPicker
                    value={filterParameter}
                    selectedStudy={props.selectedStudy}
                    selectedFilter={props.filter}
                    selectedFile={props.selectedFile}
                    onChange={(data) => { setFilterParameter(data); console.log("Filter parameter data changed ", data); }}
                />
            </div>

            <div style={{ display: "flex", alignSelf: "end" }}>
                <Button appearance="primary" style={{ width: "100px" }} onClick={() => onSearchClick()}>Search</Button>
                <Button appearance="subtle" icon={<Dismiss24Filled />} onClick={() => { resetFilters(); getData(); }} />
            </div>

        </div>

        <DataGridScrollableWrapper columnsCount={columns.length} columnWidth={150}>
            <DataGrid
                items={rows}
                columns={columns}
                selectedItems={selectedRow ? [selectedRow] : []}
                sortable
                sortState={sortState}
                subtleSelection
                selectionMode="single"
            >
                <DataGridHeader style={{ ...DataGridScrollableHeaderStyles }}>
                    <DataGridRow selectionCell={null} style={{ alignItems: 'stretch' }}>
                        {({ renderHeaderCell, columnId }) => (
                            <DataGridHeaderCell
                                style={{ backgroundColor: getHeaderCellBackground(columnId.toString()), cursor: "pointer" }}
                                sortDirection={sortState.sortColumn == columnId ? sortState.sortDirection : undefined}
                                onClick={() => onColumnClick(columnId.toString())}
                            >{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>

                {(isLoadingRows || isFetchingRows) &&
                    <Skeleton>
                        {[...Array(20)].map(() => <SkeletonItem size={40} style={{ marginTop: '4px' }} />)}
                    </Skeleton>
                }

                {(!isLoadingRows && !isFetchingRows) &&
                    <DataGridBody<RowModel>>
                        {({ item, rowId }) => (
                            <DataGridRow<RowModel>
                                key={rowId}
                                selectionCell={null}
                                onContextMenu={(ev) => {
                                    ev.preventDefault();
                                    setSelectedRow(rowId);
                                    contextMenuRef.current?.showMenu(ev as any, item);
                                }}
                            >
                                {({ renderCell }) => (
                                    <DataGridCell>{renderCell(item)}</DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                }
            </DataGrid>
        </DataGridScrollableWrapper>

        <ContextMenuWithData ref={contextMenuRef} >
            {(data) => {
                console.log("Rendering context menu items.", data);
                return getContextItems(data);
            }}
        </ContextMenuWithData>

        <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me page-item'}
            breakLinkClassName={'page-link'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            forcePage={currentPage - 1}
            onPageChange={(page) => { console.log("Selected page:", page); setCurrentPage(page.selected + 1); }}
            containerClassName={'pagination'}
            activeClassName={'active'}
        />

        <DispatcherJobDetails isExample={props.isExample} jobId={selectedDispatcherJobId} isOpened={showDispatcherJobDetailsModal} onClose={() => setShowDispatcherJobDetailsModal(false)} />


    </div >;
})