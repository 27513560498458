import moment from "moment";
import { ExamPostModel, ExamStatus, ExercisePostModel } from "../../swagger-clients/s365-take-home-exams-v2-clients.service";
import { groupBy } from "../../utils/helpers/array.helpers";
import { IExam, IExercise, IExerciseParameter } from "./exams.models";


export const mapIExamToExamPostModel = (exam: IExam): ExamPostModel => {
    let model = { ...exam, exercises: [] } as ExamPostModel;
    exam.exercises.forEach((exercise, exIndex) => {
        if (exercise.subExercises && exercise.subExercises.length > 0) {
            model.exercises.push(...exercise.subExercises as unknown as ExercisePostModel[])
        } else {
            model.exercises.push({ ...exercise } as unknown as ExercisePostModel);
        }

    });
    return model;
}

export const mapExamPostModelToIExam = (exam: ExamPostModel): IExam => {

    let model = { ...exam,  exercises: [] } as IExam;

    const sortedExercises = exam.exercises.sort((a, b) => {
        return a.order > b.order ? 1 : -1;
    });
    const groupedExercises = groupBy(sortedExercises, "order");
    console.log("groupedExercises", groupedExercises);

    Object.keys(groupedExercises).map(function (key, index) {
        var exercises: ExercisePostModel[] = groupedExercises[key];
        if (exercises.length == 1) {
            model.exercises.push(mapExercisePostModelToIExercise(exercises[0], index, undefined));
        } else {
            exercises = exercises.sort((a, b) => {
                return a.subExerciseOrder > b.subExerciseOrder ? 1 : -1;
            });
            let exercise = {
                order: +key,
                id: exercises[0].id,
                exerciseIndex: index,
                subExerciseOrder: undefined,
                template: exercises[0].template,
                examId: undefined,
                inputParameters: [],
                outputParameters: [],
                subExercises: exercises.map((x, subindex) => mapExercisePostModelToIExercise(x, index, subindex))

            } as IExercise;
            model.exercises.push(exercise);
        }


    });

    return model;

}
const mapExercisePostModelToIExercise = (exerciseModel: ExercisePostModel, exIndex: number, subExIndex?: number): IExercise => {
    var exercise = { ...exerciseModel } as unknown as IExercise;
    const sortedInputParams = exerciseModel.inputParameters.sort((a, b) => {
        if (a.order != b.order) {
            return a.order > b.order ? 1 : -1;
        }
        else {
            return a.id > b.id ? 1 : -1;
        }
    });
    const sortedOutputParams = exerciseModel.outputParameters.sort((a, b) => {
        if (a.order != b.order) {
            return a.order > b.order ? 1 : -1;
        }
        else {
            return a.id > b.id ? 1 : -1;
        }
    });
    exercise.inputParameters = sortedInputParams.map(inParam => ({ ...inParam, exerciseIndex: exIndex, subexerciseIndex: subExIndex } as IExerciseParameter));
    exercise.outputParameters = sortedOutputParams.map(outParam => ({ ...outParam, exerciseIndex: exIndex, subexerciseIndex: subExIndex } as IExerciseParameter));
    exercise.subExercises = [];
    return exercise;

}

export const getDateString = (date?: Date) => {
    if (!date)
        return null;

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month}-${day}`;

}

export const getExamStatus = (status: ExamStatus) => {

    switch (status) {
        case ExamStatus.Draft:
            return "Draft";
            break;
        case ExamStatus.Cancelled:
            return "Cancelled";
            break;
        case ExamStatus.Finished:
            return "Finished";
        case ExamStatus.Started:
            return "Started";
        default:
            return "";

    }
}


