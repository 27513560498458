import { useMsal } from "@azure/msal-react";
import { Combobox, Field, Input, Label, Switch, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { InfoButton } from "@fluentui/react-components/unstable";
import { Option } from "@fluentui/react-components";

import { CalendarLtrRegular, DocumentTextRegular, Save20Regular } from "@fluentui/react-icons";
import React, { forwardRef, useReducer, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import GroupPicker from "../../components/group-picker/group-picker.component";
import { getFilesClient, getUserToken } from "../../services/dashboard.service";
import { getAnalyzeFileClient } from "../../services/excel-runner.service";
import { getExamsClient } from "../../services/take-home-exams.service";
import { AnalyzeFilePostModel } from "../../swagger-clients/excel-runner-api-clients.service";
import { BreadcrumbItem, FileModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { ExamStatus } from "../../swagger-clients/s365-take-home-exams-v2-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { nameof } from "../../utils/helpers/validation.helpers";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { IDropdownOption } from "../../utils/shared.types";
import { ExamsRouteParams } from "../exams.component";
import { getDateString, mapExamPostModelToIExam, mapIExamToExamPostModel } from "../shared/exam.utilities";
import { IExam, IExercise } from "../shared/exams.models";
import { validate } from "./edit-exam.validation";
import "./edit-exam.styless.scss";
import { ExercisesTable } from "./exercises-table/exercises-table.component";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ImportExamButton } from "./import-exam-button/import-exam-button.component";


type EditExamProps = {

}
const emptyBreadcrumbs = [{ name: "My Work" } as BreadcrumbItem];

export const EditExam: React.FC<EditExamProps> = () => {
    const [exam, setExam] = React.useState<IExam>({
        name: undefined,
        groupId: undefined,
        startTime: undefined,
        worksheet: undefined,
        date: new Date(),
        duration: undefined,
        firstRow: 4,
        stepSize: undefined,
        startedAt: undefined,
        status: undefined,
        autoStart: undefined,
        autoEnd: undefined,
        gradingTolerance: undefined,
        introductionTemplate: undefined,
        exercises: []
    });



    const routeParams = useParams<ExamsRouteParams>();
    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [isFormSubmitted, SetIsFormSubmitted] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const [sheetsDropdownOptions, setSheetsDropdownOptions] = React.useState<IDropdownOption[]>([]);
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const cloneId = searchParams.get('cloneId');

    const { instance } = useMsal();

    const account = instance.getActiveAccount();

    React.useEffect(() => {
        OnInitialize();
    }, []);

    const CustomDateInputField = forwardRef((props: any, ref: any) => (
        <Field className="input-form-field">
            <Input
                value={props.value}
                onClick={props.onClick}
                contentAfter={<CalendarLtrRegular onClick={props.onClick} />}
                ref={ref} /></Field>

    ));

    const OnInitialize = async () => {
        if (routeParams.examId) {
            const examResp = await getExam(+routeParams.examId);
            setExam({ ...examResp });
        } else {
            if (cloneId) {
                getCloneData(+cloneId);
            }
        }
        getFile();
        analyzeFileGetFilterAndSheets();
    }

    const getExam = async (id: number) => {
        const messageId = loadingService.showMessage("Loading exam...");
        try {

            const client = getExamsClient();
            const exam = await client.getExam(id);
            const mappedExam = mapExamPostModelToIExam(exam);
            console.log("exam, mappedExam", exam, mappedExam);
            return mappedExam;
        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to get exam.");
        } finally {
            loadingService.hideMessage(messageId);
        }
    }

    const getCloneData = async (examId: number) => {
        loadingService.showLoading("Getting clone data...", async (hideMessage) => {
            try {
                var clonedExam = await getExam(+examId);
                clonedExam.id = 0;
                clonedExam.status = ExamStatus.Draft;
                clonedExam.startedAt = undefined;
                clonedExam.exercises.forEach(exercise => {
                    resetExercise(exercise);
                    exercise.subExercises.forEach(subExercise => {
                        resetExercise(subExercise);
                    });
                });
                setExam(clonedExam);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to get clone data.");
            } finally {
                hideMessage();
            }
        });
    }
    const resetExercise = (exercise: IExercise) => {
        exercise.id = 0;
        exercise.examId = 0;
        exercise.inputParameters.forEach(inparam => {
            inparam.id = 0;
            inparam.exerciseId = 0;
        });
        exercise.outputParameters.forEach(outParam => {
            outParam.id = 0;
            outParam.exerciseId = 0;
        });
    }

    const getFile = async () => {
        loadingService.showLoading("Getting file...", async (hideMessage) => {
            try {
                const client = getFilesClient();
                const resp = await client.getFileLatest(routeParams.uniquefileId!, true);
                if (resp) {
                    setSelectedFile(resp.file);
                    setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
                }
                return resp;

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting file information.");
            } finally {
                hideMessage();
            }
        });

    }

    const analyzeFileGetFilterAndSheets = async () => {
        loadingService.showLoading("Analyzing Excel file, loading sheets...", async (hideMessage) => {
            try {
                let client = getAnalyzeFileClient();

                const accessToken = await getUserToken();

                var postModel = new AnalyzeFilePostModel({
                    fileId: routeParams.uniquefileId,
                    driveId: "s365v2",
                    accessToken: accessToken
                });

                //   setAnalysisRequestInProgress(true);
                let response = await client.analyzeFilterAndSheets(postModel);

                var dropDownOptions = response.sheets.map(s => {
                    return { key: s, text: s } as IDropdownOption;
                });
                setSheetsDropdownOptions(dropDownOptions);


                // If there is only one sheet, select it by default
                if (dropDownOptions && dropDownOptions.length == 1) {
                    setExam(exam => ({ ...exam, worksheet: dropDownOptions[0].key.toString() }));
                }


            }
            catch (error) {
                processServerError(error, undefined, "An error occured while analyzing Excel file.");
            }
            finally {
                hideMessage();
            }
        });

    }
    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }

    const onSaveClick = async () => {
        SetIsFormSubmitted(true);
        loadingService.showLoading("Saving...", async (hideMessage) => {
            try {
                console.log("onSaveClick", exam);


                let validationResult = validate(exam);
                if (validationResult.isInvalid()) {
                    let globalErrors = validationResult.getGlobalErrors();
                    if (globalErrors && globalErrors.length > 0) {
                        toast.error(globalErrors.join("\n"));
                    }
                    toast.error("There are 1 or more validation errors.");
                } else {
                    let model = mapIExamToExamPostModel(exam);
                    if (routeParams.examId) {
                        model.id = +routeParams.examId;
                    }
                    console.log("save model", model);
                    model.fileDriveId = routeParams.uniquefileId;
                    model.flowsheetsDriveId = "s365v2";
                    console.log("Exam post model", model);
                    const client = getExamsClient();

                    const createdExam = await client.createOrUpdateExam(model);
                    if (!routeParams.examId) {
                        toast.success("Exam created.");
                        navigate(`/files/${routeParams.uniquefileId}/exams/edit/${createdExam.id}`);
                    } else {
                        toast.success("Exam updated.");
                    }
                }
            } catch (error) {

                processServerError(error, undefined, "An error occurred while saving exam.");

            } finally {
                hideMessage();
            }
        });



    }

    let validationResult = validate(exam);
    console.log("validationResult", validationResult, isFormSubmitted);

    return <div className="content-wrapper edit-exam">

        <div className='toolbar__wrapper'>
            <Toolbar>
                <ToolbarButton appearance='subtle' disabled={isLoading}
                    onClick={() => { onSaveClick(); }}
                    icon={<Save20Regular />}>Save</ToolbarButton>
                <ImportExamButton loadingService={loadingService} onSuccess={(item: IExam) => { setExam({ ...item }); }} />
                {routeParams && routeParams.examId &&
                    <ToolbarButton appearance='subtle' disabled={isLoading}
                        onClick={() => { navigate(`/files/${selectedFile?.uniqueIdentifier}/exams/details/${routeParams.examId}`); }}
                        icon={<DocumentTextRegular />}>View Details</ToolbarButton>}
                <LoadingIndicator loadingService={loadingService} />
            </Toolbar>
        </div>

        <div className='breadcrumbs-wrapper'>
            <Breadcrumbs>
                {breadcrumbs.map((item: BreadcrumbItem) => {
                    return <Breadcrumb
                        key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                        onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                })}
                {selectedFile &&
                    <Breadcrumb
                        key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                        onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/exams`)}
                    >{selectedFile.name}</Breadcrumb>}
                <Breadcrumb key={`breadcrumb-sensitivity-studies`} active={true}>Exams</Breadcrumb>

            </Breadcrumbs>
        </div>

        <div className="input-form">
            <div className="input-form-item">
                <Label className="input-form-label">
                    Exam name:
                </Label>
                <Field
                    className="input-form-field"
                    validationMessage={validationResult.getFieldValidationMessage(nameof<IExam>('name'), isFormSubmitted)}
                    validationState={isFormSubmitted && validationResult.getFieldValidationMessage(nameof<IExam>('name'), isFormSubmitted) ? "error" : "none"}>
                    <Input
                        value={exam?.name ?? ""}
                        disabled={isLoading}
                        onChange={(ev, data) => { setExam(exam => ({ ...exam, name: data.value })) }} />
                </Field>
            </div>

            <div className="input-form-item">
                <Label className="input-form-label">
                    Group:
                </Label>
                <GroupPicker
                    userId={account.localAccountId}
                    value={exam ? exam.groupId : undefined}
                    isSubmitted={isFormSubmitted}
                    required
                    onChange={(groupId: string) => { setExam(exam => ({ ...exam, groupId: groupId })) }}
                />
            </div>
            <div className="input-form-item">
                <Label className="input-form-label">
                    Date:
                </Label>
                <DatePicker
                    selected={exam?.date}
                    dateFormat="dd.MM.yyyy."
                    onChange={(date) => setExam(exam => ({ ...exam, date: date }))}
                    customInput={<CustomDateInputField />} />
            </div>

            <div className="input-form-item">
                <Label className="input-form-label">
                    Start time:
                </Label>
                <Field
                    className="input-form-field"
                    validationMessage={validationResult.getFieldValidationMessage(nameof<IExam>('startTime'), isFormSubmitted)}
                    validationState={isFormSubmitted && validationResult.getFieldValidationMessage(nameof<IExam>('startTime'), isFormSubmitted) ? "error" : "none"}>

                    <Input
                        value={exam?.startTime ?? ""}
                        disabled={isLoading}
                        onChange={(ev, data) => { setExam(exam => ({ ...exam, startTime: data.value })) }} />
                </Field>
            </div>

            <div className="input-form-item">
                <Label className="input-form-label">
                    Duration:
                </Label>
                <Field
                    className="input-form-field"
                    validationMessage={validationResult.getFieldValidationMessage(nameof<IExam>('duration'), isFormSubmitted)}
                    validationState={isFormSubmitted && validationResult.getFieldValidationMessage(nameof<IExam>('duration'), isFormSubmitted) ? "error" : "none"}>
                    <Input
                        value={exam?.duration ?? ""}
                        disabled={isLoading}
                        onChange={(ev, data) => { setExam(exam => ({ ...exam, duration: data.value })) }} />
                </Field>
            </div>

            <div className="input-form-item">
                <Label className="input-form-label">
                    Auto start:
                </Label>
                <Field
                    className="input-form-field"
                    validationMessage={validationResult.getFieldValidationMessage(nameof<IExam>('autoStart'), isFormSubmitted)}
                    validationState={isFormSubmitted && validationResult.getFieldValidationMessage(nameof<IExam>('autoStart'), isFormSubmitted) ? "error" : "none"}>
                    <Switch
                        checked={exam?.autoStart}
                        disabled={isLoading}
                        onChange={(ev, data) => { setExam(exam => ({ ...exam, autoStart: data.checked })) }} />
                </Field>
            </div>

            <div className="input-form-item">
                <Label className="input-form-label">
                    Auto end:
                </Label>
                <Field
                    className="input-form-field"
                    validationMessage={validationResult.getFieldValidationMessage(nameof<IExam>('autoEnd'), isFormSubmitted)}
                    validationState={isFormSubmitted && validationResult.getFieldValidationMessage(nameof<IExam>('autoEnd'), isFormSubmitted) ? "error" : "none"}>
                    <Switch
                        checked={exam?.autoEnd}
                        disabled={isLoading}
                        onChange={(ev, data) => { setExam(exam => ({ ...exam, autoEnd: data.checked })) }} />
                </Field>
            </div>

            <div className="input-form-item">
                <Label className="input-form-label">
                    Grading tolerance (%):
                </Label>

                <Field
                    className="input-form-field"
                    validationMessage={validationResult.getFieldValidationMessage(nameof<IExam>('gradingTolerance'), isFormSubmitted)}
                    validationState={isFormSubmitted && validationResult.getFieldValidationMessage(nameof<IExam>('gradingTolerance'), isFormSubmitted) ? "error" : "none"}>
                    <Input
                        type="number"
                        value={exam?.gradingTolerance?.toString() ?? ""}
                        disabled={isLoading}
                        onChange={(ev, data) => { setExam(exam => ({ ...exam, gradingTolerance: +data.value })) }}
                    />
                </Field>
            </div>

            <div className="input-form-item">
                <Label className="input-form-label">
                    Worksheet:
                </Label>

                <Field
                    className="input-form-field"
                    validationMessage={validationResult.getFieldValidationMessage(nameof<IExam>('worksheet'), isFormSubmitted)}
                    validationState={isFormSubmitted && validationResult.getFieldValidationMessage(nameof<IExam>('worksheet'), isFormSubmitted) ? "error" : "none"}>
                    <Combobox
                        value={exam.worksheet}
                        placeholder="Select sheet"
                        onOptionSelect={(ev, data) => { setExam(exam => ({ ...exam, worksheet: data.optionValue })) }}>
                        {sheetsDropdownOptions.map((option) => {
                            return <Option value={option.key?.toString()}>
                                {option.text}
                            </Option>
                        })}
                    </Combobox>
                </Field>
            </div>

            <div className="input-form-item">
                <Label className="input-form-label">
                    First row:
                </Label>
                <Field
                    className="input-form-field"
                    validationMessage={validationResult.getFieldValidationMessage(nameof<IExam>('firstRow'), isFormSubmitted)}
                    validationState={isFormSubmitted && validationResult.getFieldValidationMessage(nameof<IExam>('firstRow'), isFormSubmitted) ? "error" : "none"}>
                    <Input
                        type="number"
                        value={exam?.firstRow?.toString() ?? ""}
                        disabled={isLoading}
                        onChange={(ev, data) => { setExam(exam => ({ ...exam, firstRow: +data.value })) }} />
                </Field>
                <InfoButton info={<>The row number in your Excel file where your data starts.</>} />
            </div>
            <div className="input-form-item">
                <Label className="input-form-label">
                    Step size:
                </Label>
                <Field
                    className="input-form-field"
                    validationMessage={validationResult.getFieldValidationMessage(nameof<IExam>('stepSize'), isFormSubmitted)}
                    validationState={isFormSubmitted && validationResult.getFieldValidationMessage(nameof<IExam>('stepSize'), isFormSubmitted) ? "error" : "none"}>
                    <Input
                        type="number"
                        value={exam?.stepSize?.toString() ?? ""}
                        disabled={isLoading}
                        onChange={(ev, data) => { setExam(exam => ({ ...exam, stepSize: +data.value })) }} />
                </Field>
            </div>



        </div>

        <ExercisesTable
            exercises={exam?.exercises ?? []}
            examId={exam.id}
            isRequestInProgress={isLoading}
            isLoading={isLoading}
            loadingService={loadingService}
            introductionTemplate={exam.introductionTemplate}
            onChange={(exercises: IExercise[]) => {
                setExam(exam => ({
                    ...exam,
                    exercises: exercises.map(x => ({ ...x, subExercises: [...x.subExercises] } as IExercise))
                } as IExam)
                );
            }
            }
            onIntroductionTemplateChange={(data) => {
                setExam(exam => ({ ...exam, introductionTemplate: data }))
            }}
            validationResult={validationResult}
            isFormSubmitted={isFormSubmitted}
        />



    </div>;
}