import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, DialogActions } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { HubConnection } from "@microsoft/signalr";
import React from "react";
import { CloneSharedFilterPostModel, FileModel, FilterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { SaveFileModal, SaveFileModel } from "../../components/save-file-modal/save-file-modal.component";
import { getFileExtension, getFileNameWithoutExtension } from "../../files/file-type-icon/file-type-icon.helpers";
import { getFiltersClient } from "../../services/dashboard.service";
import { toast } from "react-toastify";
import { processServerError } from "../../utils/helpers/error.helper";
import { CloneSharedDoeExperimentPostModel, ScenarioResultModel } from "../../swagger-clients/excel-runner-api-clients.service";
import { ScenarioDetails, ScenarioDetailsType } from "../scenario-details/scenario-details.component";
import { getDoeExperimentsClient } from "../../services/excel-runner.service";

type ScenarioDetailsModalProps = {
    scenarioId: number;
    scenarioName?: string;
    isOpened?: boolean;
    hubConnection: HubConnection;
    onClose?: () => void;
}

export const ScenarioDetailsModal: React.FC<ScenarioDetailsModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [selectedFilter, setSelectedFilter] = React.useState<FilterResultModel>();
    const [selectedScenario, setSelectedScenario] = React.useState<ScenarioResultModel>();
    const [showSaveFileModal, setShowSaveFileModal] = React.useState<boolean>(false);
    const scenarioDetailsRef = React.useRef<ScenarioDetailsType>();

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    const onClose = () => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }

    const onCloneSharedScenarioClick = (scenario: ScenarioResultModel, file: FileModel, filter: FilterResultModel) => {
        setSelectedFile(file);
        setSelectedScenario(scenario);
        setSelectedFilter(filter);
        setShowSaveFileModal(true);
    }

    const onCloneScenarioFilterAndFlowsheetToDashboard = async (data: SaveFileModel) => {
        const messageId = scenarioDetailsRef.current?.showLoadingMessage("Cloning experiment...");
        try {
            setShowSaveFileModal(false);
            const model = new CloneSharedDoeExperimentPostModel({
                experimentId: props.scenarioId,
                filename: `${data.name}.${data.extension}`,
                destinationUniqueId: data.parentDirectoryUniqueId,
                conflictAction: data.conflictAction
            });
            const client = getDoeExperimentsClient();
            await client.cloneSharedExperiment(model);

            toast.success("Experiment cloned successfully.");
            onClose();
        } catch (error) {
            processServerError(error, undefined, "An error occurred while cloning shared experiment.");
        } finally {
            scenarioDetailsRef.current?.hideLoadingMessage(messageId);
        }


    }


    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onClose();
        }

    }}>
        <DialogSurface style={{ minWidth: "90vw" }}>

            <DialogBody style={{ height: "90vh" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onClose} icon={<Dismiss24Filled />} />}
                    style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <span>{!!props.scenarioName ? `Shared with me >  Design of Experiments (DoE) > ${props.scenarioName}` : "Shared Design of Experiment (DoE) details"}</span>
                </DialogTitle>
                <DialogContent>
                    <ScenarioDetails
                        ref={scenarioDetailsRef}
                        isSharedView
                        scenarioId={props.scenarioId}
                        onCloneSharedScenarioClick={onCloneSharedScenarioClick}
                        hubConnection={props.hubConnection} />

                    {selectedFile && selectedFilter && selectedScenario && showSaveFileModal &&
                        <SaveFileModal
                            isOpened={true}
                            modalTitle={"Clone Experiment and Flowsheet"}
                            saveButtonLabel="Clone"
                            defaultName={getFileNameWithoutExtension(selectedFile.name)}
                            fileExtension={getFileExtension(selectedFile.name)}
                            fileExtensionText={`DWSIM file (*.${getFileExtension(selectedFile.name)})`}
                            onHide={() => { setShowSaveFileModal(false); setSelectedFile(undefined); setSelectedFilter(undefined); }}
                            onSuccess={onCloneScenarioFilterAndFlowsheetToDashboard}
                        />
                    }

                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}