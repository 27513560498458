import { Table, TableHeader, TableRow, TableHeaderCell, TableCell, TableColumnSizingOptions, useTableFeatures, useTableColumnSizing_unstable, createTableColumn, TableColumnDefinition, MenuItem } from "@fluentui/react-components";
import React from "react";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";
import { ShareByMeStudyResponseModel, ShareDefinitionSourceType } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { useLoading } from "../../utils/loading-indicator.component";
import { processServerError } from "../../utils/helpers/error.helper";
import { getDashboardSharingClient } from "../../services/dashboard.service";
import { FileDisplayName } from "../../components/file-display-name/file-display-name.component";
import { ShareFileModal } from "../../files/share/share-file-modal.component";
import { ContextMenu, ContextMenuType } from "../../files/context-menu/context-menu.component";
import { PeopleAddRegular } from "@fluentui/react-icons";

type SharedByMeMssTableProps = {
    isOptimumTest: boolean;
}

export const SharedByMeMssTable: React.FC<SharedByMeMssTableProps> = (props) => {

    const [isLoading, loadingService] = useLoading();
    const [selectedItem, setSelectedItem] = React.useState<ShareByMeStudyResponseModel>();
    const [showShareModal, setShowShareModal] = React.useState<boolean>(false);
    const [items, setItems] = React.useState<ShareByMeStudyResponseModel[]>([]);
    const contextMenuRef = React.createRef<ContextMenuType>();

    React.useEffect(() => {
        getSharedStudies();
    }, []);


    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        name: {
            minWidth: 100,
            defaultWidth: 400,
        },
        flowsheet: {
            minWidth: 300,
            defaultWidth: 400
        },
        created: {
            minWidth: 100,
            defaultWidth: 200
        }
    });

    const getContextItems = () => {
        if (!selectedItem) {
            return [];
        }
        let menuItems: JSX.Element[] = [];
        menuItems.push(<MenuItem key="share" icon={<PeopleAddRegular />} onClick={() => { setShowShareModal(true) }}> Share</MenuItem>);

        return menuItems;
    }


    const getSharedStudies = async () => {
        loadingService.showLoading(`Loading ${props.isOptimumTest ? "optimum tests" : "studies"}...`, async (hideMessage) => {

            try {
                const client = getDashboardSharingClient();
                const studiesResp = await client.getSharedByMeMssStudies(props.isOptimumTest);
                setItems(studiesResp ?? []);

            } catch (error) {
                processServerError(error, undefined, `An error occurred while getting ${props.isOptimumTest ? "optimum tests" : "studies"} that shared by me.`);

            } finally {
                hideMessage();
            }

        });

    }

    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "shared": return true;

            default: return false;
        }
    }

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<ShareByMeStudyResponseModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );


    return <div>
        <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()} >
            <TableHeader>
                <TableRow>
                    {columns.map((column) => (
                        <TableHeaderCell
                            onDragStart={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            className={`${isColumnCentered(column.columnId as string) ? 'files-table__cell--center' : ''} files-table__cell--bold`}
                            {...columnSizing_unstable.getTableHeaderCellProps(
                                column.columnId
                            )}
                        >
                            {column.renderHeaderCell()}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={isLoading}
                columnCount={3} loadingMessage="Loading..."
                itemCount={items ? items.length : 0}
                noItemsMessage="No items found.">
                {items && items.length > 0 && items.map((item) => {
                    return <TableRow
                        key={`scenario-${item.id}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => { setSelectedItem(item); }}
                        className={selectedItem && selectedItem.id == item.id ? "table-row-selected" : undefined}
                        onContextMenu={(ev) => { ev.preventDefault(); setSelectedItem(item); contextMenuRef.current?.showMenu(ev); }}
                    >
                        {columns.map((column) => (
                            <TableCell
                                className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                {...columnSizing_unstable.getTableCellProps(column.columnId)}
                            >
                                {column.renderCell(item)}
                            </TableCell>
                        ))}

                    </TableRow>
                })}


            </TableBodyWithLoading>
        </Table>
        <ContextMenu ref={contextMenuRef} hasIcons>
            {getContextItems()}
        </ContextMenu>

        {selectedItem && showShareModal &&
            <ShareFileModal
                sourceType={props.isOptimumTest ? ShareDefinitionSourceType.OptimumTest : ShareDefinitionSourceType.MssStudy}
                sourceName={selectedItem.name}
                sourceUniqueId={selectedItem.id.toString()}
                isOpened={showShareModal}
                onClose={() => { setShowShareModal(false); }}
            />}


    </div>
}
const columns: TableColumnDefinition<ShareByMeStudyResponseModel>[] = [
    createTableColumn<ShareByMeStudyResponseModel>({
        columnId: "name",
        renderHeaderCell: () => <>Name</>,
        renderCell: (item: ShareByMeStudyResponseModel) => {
            return <span>{item.name}</span>;

        }
    }),
    createTableColumn<ShareByMeStudyResponseModel>({
        columnId: "flowsheet",
        renderHeaderCell: () => <>Flowsheet</>,
        renderCell: (item: ShareByMeStudyResponseModel) => {
            return <FileDisplayName fileUniqueIdentifier={item.fileUniqueIdentifier} fileVersionNumber={item.fileVersionNumber} />;

        }
    }),
    createTableColumn<ShareByMeStudyResponseModel>({
        columnId: "shared",
        renderHeaderCell: () => <>Shared</>,
        renderCell: (item: ShareByMeStudyResponseModel) => {

            return <ShowLocalTime date={item.sharedAtUtc} multiline />;
        }
    })
];