import { Toolbar, ToolbarButton, TabList, Tab, MenuItem, TableColumnDefinition, createTableColumn, TableColumnSizingOptions, useTableFeatures, useTableColumnSizing_unstable } from "@fluentui/react-components";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell } from "@fluentui/react-components";
import { Add20Regular, ArrowResetRegular, CopyRegular, DeleteRegular, DocumentTextRegular, PeopleAddRegular } from "@fluentui/react-icons";
import React from "react";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../components/breadcrumbs/breadcrumbs";
import ShowLocalTime from "../components/show-local-time/show-local-time.component";
import { TableBodyWithLoading } from "../components/table-body-with-loading/table-body-with-loading.component";
import { ContextMenu, ContextMenuType } from "../files/context-menu/context-menu.component";
import { LoadingIndicator, useLoading } from "../utils/loading-indicator.component";
import { BreadcrumbItem, FileModel, ShareDefinitionSourceType } from "../swagger-clients/s365-dashboard-v2-api-clients.service";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteScenarioPostModel, ScenarioResultModel, ScenariosListQuery } from "../swagger-clients/excel-runner-api-clients.service";
import { getFilesClient, getUsersClient, getUserToken } from "../services/dashboard.service";
import { processServerError } from "../utils/helpers/error.helper";
import { GetAnalysisStatus, GetProcessingStatus, GetCompletedJobsText } from "./utilities/scenario.helpers";
import { toast } from "react-toastify";
import { getScenariosClient } from "../services/excel-runner.service";
import { ConfirmationDialog } from "../components/confirmation-dialog/confirmation-dialog.component";
import "./scenarios-table.styless.scss";
import { _copyAndSort } from "../utils/helpers/array.helpers";
import { JobProgressCount } from "../components/job-progress-count/job-progress-count.component";
import { NewExperimentModal } from "./new-experiment.modal";
import { ShareFileModal } from "../files/share/share-file-modal.component";
import { flowsheetExtensions } from "../global.variables";
import { getFileExtension } from "../files/file-type-icon/file-type-icon.helpers";

type ScenariosTableProps = {
    isExcelBased?: boolean;
}

type ScenarioRouteParams = {
    uniquefileId: string,
    scenarioId?: string
}
export const emptyBreadcrumbs = [{ name: "My Work" } as BreadcrumbItem];


export const ScenariosTable: React.FC<ScenariosTableProps> = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const routeParams = useParams<ScenarioRouteParams>();
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [selectedScenario, setSelectedScenario] = React.useState<ScenarioResultModel>();
    const [scenarios, setScenarios] = React.useState<ScenarioResultModel[]>([]);
    const [selectedTab, setSelectedTab] = React.useState<string>("active");
    const [showDeleteScenarioDialog, setShowDeleteScenarioDialog] = React.useState<boolean>(false);
    const [showNewExperimentModal, setShowNewExperimentModal] = React.useState<boolean>(false);
    const [showShareModal, setShowShareModal] = React.useState<boolean>(false);
    const [isUserExamplesAdmin, setIsUserExamplesAdmin] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();

    React.useEffect(() => {
        checkIfUserIsExamplesAdmin();
        getFile();
    }, [])

    React.useEffect(() => {
        if (selectedFile?.uniqueIdentifier) {
            getScenarios();
        }
    }, [selectedFile?.uniqueIdentifier]);


    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        id: {
            minWidth: 50,
            defaultWidth: 100,
        },
        name: {
            minWidth: 100,
            defaultWidth: 400,
        },
        analysisStatus: {
            minWidth: 100,
            defaultWidth: 200
        },
        processingStatus: {
            minWidth: 100,
            defaultWidth: 200
        },
        jobs: {
            minWidth: 100,
            defaultWidth: 200

        },
        created: {
            minWidth: 100,
            defaultWidth: 200

        }
    });


    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "analysisStatus": return true;
            case "processingStatus": return true;
            case "jobs": return true;
            case "created": return true;

            default: return false;
        }
    }

    const checkIfUserIsExamplesAdmin = async () => {
        try {
            const client = getUsersClient();
            const resp = await client.isUserExamplesAdmin();
            setIsUserExamplesAdmin(resp?.isUserExamplesAdmin ?? false);
        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to check if user is Examples Admin.");
        }

    }

    const getFile = async () => {
        loadingService.showLoading("Getting file...", async (hideMessage) => {
            try {
                const client = getFilesClient();
                const resp = await client.getFileLatest(routeParams.uniquefileId!, true);
                if (resp) {
                    setSelectedFile(resp.file);
                    setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
                }
                return resp;

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting file information.");
            } finally {
                hideMessage();
            }
        });

    }


    const getScenarios = () => {
        loadingService.showLoading("Getting Design of Experiments...", async (hideMessage) => {
            try {
                const client = getScenariosClient();
                const accessToken: string = await getUserToken();
                const query = { siteId: "s365v2", accessToken: accessToken, driveItemId: routeParams.uniquefileId, flowsheetsListId: "s365v2" } as ScenariosListQuery;
                const scenariosResp = await client.getScenarios(query);
                const scenariosSorted = _copyAndSort<ScenarioResultModel>(scenariosResp, "id", true);
                setScenarios(scenariosSorted ?? []);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to get experiments.");
            } finally {
                hideMessage();
            }
        });


    }
    const onDeleteScenarioClick = () => {
        loadingService.showLoading("Archiving Design of Experiment...", async (hideMessage) => {
            try {


                const client = getScenariosClient();

                const accessToken: string = await getUserToken();

                const query = {
                    scenarioId: selectedScenario.id,
                    accessToken: accessToken
                } as unknown as DeleteScenarioPostModel;

                await client.deleteScenario(query);
                toast.success("Successfully archived Design of Experiment.");
                setShowDeleteScenarioDialog(false);
                setSelectedScenario(undefined);
                getScenarios();

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to archive Design of Experiment.");
            } finally {
                hideMessage();
            }
        });
    }

    const onRestoreScenarioClick = () => {
        loadingService.showLoading("Restoring Design of Experiment...", async (hideMessage) => {
            try {
                const client = getScenariosClient();
                await client.restoreScenario(selectedScenario.id);
                toast.success("Successfully restored Design of Experiment.");
                setSelectedScenario(undefined);
                getScenarios();

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to restore Design of Experiment.");
            } finally {
                hideMessage();
            }
        });
    }

    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }
    const onShowDetailsClick = (scenarioId: number) => {
        navigate(`/files/${selectedFile?.uniqueIdentifier}/${props.isExcelBased ? "doe-xls" : "doe"}/details/${scenarioId}`)
    }

    const getContextItems = () => {
        if (!selectedScenario) {
            return [];
        }
        let menuItems: JSX.Element[] = [];

        if (!selectedScenario.isDeleted) {
            menuItems.push(<MenuItem icon={<CopyRegular />} key="clone" onClick={() => navigate(`/files/${selectedFile?.uniqueIdentifier}/${props.isExcelBased ? "doe-xls" : "doe"}/edit?cloneId=${selectedScenario?.id}`)}> Clone</MenuItem>);

            const showShare = selectedFile && flowsheetExtensions.indexOf(getFileExtension(selectedFile.name)) > -1;
            if(showShare){
                menuItems.push(<MenuItem key="share" icon={<PeopleAddRegular />} onClick={() => { setShowShareModal(true) }}> Share</MenuItem>);
            }    

            menuItems.push(<MenuItem icon={<DeleteRegular />} key="delete" onClick={() => { setShowDeleteScenarioDialog(true); }}> Archive</MenuItem>);
        } else {
            menuItems.push(<MenuItem key="restore" icon={<ArrowResetRegular />} onClick={() => { onRestoreScenarioClick(); }}> Restore</MenuItem >);
        }



        return menuItems;
    }

    const contextMenuRef = React.createRef<ContextMenuType>();
    const items = selectedTab == "active" ? scenarios.filter(scenario => !scenario.isDeleted) : scenarios.filter(scenario => !!scenario.isDeleted);

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<ScenarioResultModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );

    return <div className='content-wrapper'>
        <div className="scenarios-wrapper">
            <div className='toolbar__wrapper'>
                <Toolbar>

                    <ToolbarButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                        onClick={() => {
                            if (!!props.isExcelBased) {
                                navigate(`/files/${selectedFile!.uniqueIdentifier!}/doe-xls/edit`)
                            } else {
                                setShowNewExperimentModal(true);
                            }

                        }}
                        appearance='primary'
                        icon={<Add20Regular />}>New Experiment</ToolbarButton>
                    <LoadingIndicator loadingService={loadingService} />
                </Toolbar>
            </div>

            <div className='scenarios-wrapper__breadcrumbs-wrapper'>
                <Breadcrumbs>
                    {breadcrumbs.map((item: BreadcrumbItem) => {
                        return <Breadcrumb
                            key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                            onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                    })}
                    {selectedFile &&
                        <Breadcrumb
                            key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                            onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/doe`)}
                        >{selectedFile.name}</Breadcrumb>}
                    <Breadcrumb key={`breadcrumb-scenarios`} active={true}> Design of Experiments</Breadcrumb>

                </Breadcrumbs>
            </div>



            <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { contextMenuRef.current?.hideMenu(); setSelectedTab(data.value as string); setSelectedScenario(undefined); }}>
                <Tab key="active" value="active" >Active</Tab>
                <Tab key="deleted" value="deleted">Archived</Tab>
            </TabList>
            <div className="tab-content">
                <div className='scenarios-table__wrapper'>
                    <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()} >
                        <TableHeader>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell
                                        onDragStart={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        className={`${isColumnCentered(column.columnId as string) ? 'files-table__cell--center' : ''} files-table__cell--bold`}
                                        {...columnSizing_unstable.getTableHeaderCellProps(
                                            column.columnId
                                        )}
                                    >
                                        {column.renderHeaderCell()}
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBodyWithLoading isLoading={isLoading}
                            columnCount={6} loadingMessage="Loading..."
                            itemCount={items ? items.length : 0}
                            noItemsMessage="No items found.">
                            {items && items.length > 0 && items.map((item) => {
                                return <TableRow
                                    key={`scenario-${item.id}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { setSelectedScenario(item); onShowDetailsClick(item.id); }}
                                    className={selectedScenario && selectedScenario.id == item.id ? "table-row-selected" : undefined}
                                    onContextMenu={(ev) => { ev.preventDefault(); setSelectedScenario(item); contextMenuRef.current?.showMenu(ev); }}>
                                    {columns.map((column) => (
                                        <TableCell
                                            className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                            {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                        >
                                            {column.renderCell(item)}
                                        </TableCell>
                                    ))}

                                </TableRow>
                            })}


                        </TableBodyWithLoading>
                    </Table>
                </div>
            </div>

        </div>
        <ContextMenu ref={contextMenuRef} hasIcons>
            {getContextItems()}
        </ContextMenu>

        {selectedFile && showNewExperimentModal &&
            <NewExperimentModal
                fileId={selectedFile.id}
                fileUniqueIdentifier={selectedFile.uniqueIdentifier}
                isOpened={true}
                onClose={() => { setShowNewExperimentModal(false); }} />}

        {
            selectedScenario && showShareModal &&
            <ShareFileModal
                sourceType={ShareDefinitionSourceType.DoEExperiment}
                sourceUniqueId={selectedScenario.id?.toString()}
                sourceName={selectedScenario.name}
                isOpened={showShareModal}
                onClose={() => { setShowShareModal(false); }}
            />
        }

        <ConfirmationDialog
            isOpened={showDeleteScenarioDialog}
            title="Archive Scenario"
            confirmLabel="Archive"
            subText={
                `Are you sure you want to archive scenario '${selectedScenario?.name}'? `}
            onConfirm={() => {
                onDeleteScenarioClick();
            }}
            onClose={() => { setShowDeleteScenarioDialog(false); }}
        />

    </div >
}


const columns: TableColumnDefinition<ScenarioResultModel>[] = [
    createTableColumn<ScenarioResultModel>({
        columnId: "id",
        renderHeaderCell: () => <>ID</>,
        renderCell: (item: ScenarioResultModel) => {
            return <span>{item.id}</span>;

        }
    }),
    createTableColumn<ScenarioResultModel>({
        columnId: "name",
        renderHeaderCell: () => <>Name</>,
        renderCell: (item: ScenarioResultModel) => {
            return <span>{item.name}</span>;

        }
    }),
    createTableColumn<ScenarioResultModel>({
        columnId: "analysisStatus",
        renderHeaderCell: () => <>Analysis Status</>,
        renderCell: (item: ScenarioResultModel) => {
            return GetAnalysisStatus(item.analysisStatus);
        }
    }),
    createTableColumn<ScenarioResultModel>({
        columnId: "processingStatus",
        renderHeaderCell: () => <>Processing Status</>,
        renderCell: (item: ScenarioResultModel) => {
            return GetProcessingStatus(item.processingStatus);
        }
    }),
    createTableColumn<ScenarioResultModel>({
        columnId: "jobs",
        renderHeaderCell: () => <>Completed Jobs</>,
        renderCell: (item: ScenarioResultModel) => {

            return <JobProgressCount totalJobs={item.totalRows} calculatedJobs={item.calculatedRows} failedJobs={item.failedRows} />;

        }
    }),
    createTableColumn<ScenarioResultModel>({
        columnId: "created",
        renderHeaderCell: () => <>Created</>,
        renderCell: (item: ScenarioResultModel) => {

            return <ShowLocalTime date={item.createdAt} />;
        }
    })
];