import { TabList, Tab, MenuItem, TableColumnDefinition, createTableColumn, useTableFeatures, TableColumnSizingOptions, useTableColumnSizing_unstable, SpinButton, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell } from "@fluentui/react-components";
import React from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { ContextMenu, ContextMenuType } from "../../files/context-menu/context-menu.component";
import { getSensitivityStudiesClient } from "../../services/sensitivity-studies.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { SensitivityStudyResultModel, StudyStep, StudyStepStatus, StudyType, UserSensitivityStudyQuery } from "../../swagger-clients/sensitivity-studies-api-clients.service";
import { DeleteSensitivityStudyModal } from "../delete-sensitivity-study-modal/delete-sensitivity-study-modal.component";
import { GetStudyStepText, GetStudyStepStatusText, getCompletedJobsText } from "../utilities/study.utilities";
import { FileDisplayName } from "../../components/file-display-name/file-display-name.component";
import { StudyStepStatusComponent } from "../study-step-status/study-step-status.component";
import { JobProgressCount } from "../../components/job-progress-count/job-progress-count.component";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";
import { StudyExample } from "./study-example/study-example.component";
import { ShareDefinitionSourceType } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { getDashboardSharingClient } from "../../services/dashboard.service";
import { StudyDetailsModal } from "./study-details-modal/study-details-modal.component";
import { HubConnection } from "@microsoft/signalr";
import { CreateCopilotExperimentWizardModal, WizardApplication } from "../../components/create-experiment-wizard/create-experiment-wizard.modal";
import { AddRegular } from "@fluentui/react-icons";

type AllUserStudiesProps = {
    isOptimumTester: boolean;
    hubConnection: HubConnection;
}

export const AllUserStudies: React.FC<AllUserStudiesProps> = (props) => {

    const [selectedStudy, setSelectedStudy] = React.useState<SensitivityStudyResultModel>();
    const [studies, setStudies] = React.useState<SensitivityStudyResultModel[]>([]);
    const [showDeleteStudyModal, setShowDeleteStudyModal] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();
    const contextMenuRef = React.createRef<ContextMenuType>();
    const [currentPage, SetCurrentPage] = React.useState<number>(1);
    const [sizePerPage, SetSizePerPage] = React.useState<number>(25);
    const [totalRecords, setTotalRecords] = React.useState<number>(0);
    const [orderByIdDesc, setOrderByIdDesc] = React.useState<boolean>(true);

    const [exampleIds, setExampleIds] = React.useState<number[]>([]);
    const [showStudyExampleModal, setShowStudyExampleModal] = React.useState<boolean>(false);
    const [selectedExampleStudyId, setSelectedExampleStudyId] = React.useState<number>();
    const [showCreateNewStudyWizardModal, setShowCreateNewStudyWizardModal] = React.useState<boolean>(false);

    React.useEffect(() => {
        getPublicStudies();
    }, []);

    React.useEffect(() => {
        getStudies();
    }, [currentPage, orderByIdDesc, props.isOptimumTester]);


    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        studyId: {
            defaultWidth: 100,
            minWidth: 50,
        },
        studyName: {
            minWidth: 100,
            defaultWidth: 300,
        },
        flowsheet: {
            minWidth: 200,
            defaultWidth: 400,
        },
        currentStep: {
            minWidth: 100,
            defaultWidth: 200,
        },
        currentStepStatus: {
            minWidth: 100,
            defaultWidth: 200
        },
        jobs: {
            minWidth: 100,
            defaultWidth: 200
        },
        created: {
            minWidth: 100,
            defaultWidth: 100
        }
    });

    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "studyId": return true;
            case "currentStep": return true;
            case "currentStepStatus": return true;
            case "jobs": return true;
            case "created": return true;

            default: return false;
        }
    }

    const items = studies ?? [];

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<SensitivityStudyResultModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );

    const getPublicStudies = () => {
        loadingService.showLoading(`Loading ${props.isOptimumTester ? "optimum tests" : "studies"}...`, async (hideMessage) => {
            try {
                const client = getDashboardSharingClient();

                const sharedDefinitions = await client.getPublicExamples(ShareDefinitionSourceType.MssStudy);
                const sharedDefinitionSourceIds = sharedDefinitions?.map(x => +x.sourceId) ?? [];
                setExampleIds(sharedDefinitionSourceIds);

            } catch (error) {
                processServerError(error, undefined, `An error occcurred while getting ${props.isOptimumTester ? "optimum tests" : "studies"}.`);
            } finally {
                hideMessage();
            }
        });
    }

    const onShowDetailsClick = () => {
        navigate(`/files/${selectedStudy.flowsheetDriveId}/${props.isOptimumTester ? "optimum-tester" : "sensitivity-studies"}/details/${selectedStudy.id}`);
    }
    const getStudies = async () => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {
                const client = getSensitivityStudiesClient();
                const query = {
                    studyType: props.isOptimumTester ? StudyType.OptimumTest : StudyType.SensitivityStudy,
                    isDeleted: false,
                    skip: (currentPage - 1) * sizePerPage,
                    take: sizePerPage,
                    orderByIdDesc: orderByIdDesc
                } as UserSensitivityStudyQuery;
                const studiesResp = await client.getAllUserSensitivityStudies(query);
                setStudies(studiesResp?.studies ?? []);
                setTotalRecords(studiesResp?.totalRecords ?? 0);

            } catch (error) {
                processServerError(error, undefined, `An error occcurred while getting ${props.isOptimumTester ? "optimum tests" : "sensitivity studies"}.`);
            } finally {
                hideMessage();
            }
        });

    }

    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        if (!selectedStudy?.isDeleted) {
            if (selectedStudy && (selectedStudy.currentStep == StudyStep.CalculateInitialJob ||
                (selectedStudy.currentStep == StudyStep.CalculateJobs && selectedStudy.currentStepStatus == StudyStepStatus.Ready))) {

                menuItems.push(<MenuItem key="edit" onClick={() => navigate(`/files/${selectedStudy.flowsheetDriveId}/${props.isOptimumTester ? "optimum-tester" : "sensitivity-studies"}/edit/${selectedStudy?.id}`)}> Edit</MenuItem>);
            }
            menuItems.push(...[
                <MenuItem key="clone" onClick={() => navigate(`/files/${selectedStudy.flowsheetDriveId}/${props.isOptimumTester ? "optimum-tester" : "sensitivity-studies"}/edit?cloneId=${selectedStudy?.id}`)}> Clone</MenuItem>,
                <MenuItem key="delete" onClick={() => setShowDeleteStudyModal(true)}> Delete</MenuItem>
            ]);
        }
        menuItems.push(<MenuItem key="details" onClick={() => onShowDetailsClick()} > Details</MenuItem>);

        return menuItems;
    }



    const totalPages = totalRecords > sizePerPage ? Math.ceil(totalRecords / sizePerPage) : 1;

    return <div className="content-wrapper">


        <div className='toolbar__wrapper'>
            <Toolbar>

                <ToolbarButton icon={<AddRegular />} appearance="primary" onClick={() => { setShowCreateNewStudyWizardModal(true); }}>
                    {props.isOptimumTester ? "New Optimum Test" : "New Study"}
                </ToolbarButton>


                <LoadingIndicator loadingService={loadingService} />

            </Toolbar>
        </div>

        <div className='groups-wrapper__breadcrumbs-wrapper'>
            <Breadcrumbs>
                <Breadcrumb key={`breadcrumb-applications`}>Applications</Breadcrumb>
                <Breadcrumb key={`breadcrumb-agents`} active={true}>{props.isOptimumTester ? "Optimum Tests (OT)" : "Multivariate Sensitivity Studies (MSS)"}</Breadcrumb>
            </Breadcrumbs>
        </div>

        <div id="examples" style={{ display: "flex", gap: 20 }} >
            {/* TODO: Implement examples overview for OT */}
            {!!exampleIds && !props.isOptimumTester && exampleIds.length > 0 && exampleIds.map((studyId) => {

                return <StudyExample
                    studyId={studyId}
                    onClick={() => {
                        setSelectedExampleStudyId(studyId);
                        setShowStudyExampleModal(true);
                    }} />
            })}

        </div>


        <div className='s365-table__wrapper'>
            <Table sortable ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
                <TableHeader>
                    <TableRow>

                        {columns.map((column) => (
                            <TableHeaderCell
                                onDragStart={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                className={`${isColumnCentered(column.columnId as string) ? 'files-table__cell--center' : ''} files-table__cell--bold`}
                                style={{ cursor: (column.columnId as string) == "studyId" ? "pointer" : "default" }}
                                sortable={(column.columnId as string) == "studyId"}
                                sortDirection={(column.columnId as string) == "studyId" ? (orderByIdDesc ? "descending" : "ascending") : undefined}
                                onClick={() => { if ((column.columnId as string) == "studyId") { setOrderByIdDesc(!orderByIdDesc); } }}
                                key={column.columnId}
                                {...columnSizing_unstable.getTableHeaderCellProps(
                                    column.columnId
                                )}

                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}

                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={isLoading}
                    columnCount={7} loadingMessage="Loading..."
                    itemCount={studies ? studies.length : 0}
                    noItemsMessage="No items found.">
                    {studies && studies.length > 0 && studies.map((item) => {
                        return <TableRow
                            key={`study-${item.id}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`/files/${item.flowsheetDriveId}/${props.isOptimumTester ? "optimum-tester" : "sensitivity-studies"}/details/${item.id}`)}
                            className={selectedStudy && selectedStudy.id == item.id ? "table-row-selected" : undefined}
                            onContextMenu={(ev) => { ev.preventDefault(); setSelectedStudy(item); contextMenuRef.current?.showMenu(ev); }}>
                            {columns.map((column) => (
                                <TableCell
                                    className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}

                        </TableRow>
                    })}
                </TableBodyWithLoading>
            </Table>
            <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me page-item'}
                breakLinkClassName={'page-link'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}

                onPageChange={(page) => { console.log("Selected page:", page); SetCurrentPage(page.selected + 1); }}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </div>


        <ContextMenu ref={contextMenuRef} >
            {getContextItems()}
        </ContextMenu>
        {showDeleteStudyModal && selectedStudy && <DeleteSensitivityStudyModal
            study={selectedStudy}
            isOpened={showDeleteStudyModal}
            isOptimumTester={props.isOptimumTester}
            onClose={() => setShowDeleteStudyModal(false)}
            onSuccess={() => { setShowDeleteStudyModal(false); getStudies(); }}
        />}

        {showStudyExampleModal && selectedExampleStudyId && <StudyDetailsModal
            studyId={selectedExampleStudyId}
            isOpened
            hubConnection={props.hubConnection}
            onClose={() => { setShowStudyExampleModal(false); setSelectedExampleStudyId(undefined); }}
        />}
        {showCreateNewStudyWizardModal &&
            <CreateCopilotExperimentWizardModal
                title={props.isOptimumTester ? "Create Optimum Test (OT)" : "Create Multivariate Sensitivity Study (MSS)"}
                hubConnection={props.hubConnection}
                application={props.isOptimumTester ? WizardApplication.OptimumTest : WizardApplication.MSS}
                isOpened={showCreateNewStudyWizardModal}
                onClose={() => { setShowCreateNewStudyWizardModal(false); }}
            />}
    </div>
}

const columns: TableColumnDefinition<SensitivityStudyResultModel>[] = [
    createTableColumn<SensitivityStudyResultModel>({
        columnId: "studyId",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>ID</div>,
        renderCell: (item: SensitivityStudyResultModel) => {

            return <div style={{ textAlign: "center" }}>{item.id}</div>;

        }
    }),
    createTableColumn<SensitivityStudyResultModel>({
        columnId: "studyName",
        renderHeaderCell: () => <>Study</>,
        renderCell: (item: SensitivityStudyResultModel) => {

            return item.name;

        }
    }),
    createTableColumn<SensitivityStudyResultModel>({
        columnId: "flowsheet",
        renderHeaderCell: () => <>Flowsheet</>,
        renderCell: (item: SensitivityStudyResultModel) => {

            return <FileDisplayName fileUniqueIdentifier={item.flowsheetDriveId} fileVersionNumber={item.fileVersionId ? +item.fileVersionId : undefined} />;

        }
    }),
    createTableColumn<SensitivityStudyResultModel>({
        columnId: "currentStep",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>Current step</div>,
        renderCell: (item: SensitivityStudyResultModel) => {

            return <div style={{ textAlign: "center" }}>{GetStudyStepText(item.currentStep)}</div>;

        }
    }),
    createTableColumn<SensitivityStudyResultModel>({
        columnId: "currentStepStatus",
        renderHeaderCell: () => <>Current step status</>,
        renderCell: (item: SensitivityStudyResultModel) => {

            return <div style={{ textAlign: "center" }}><StudyStepStatusComponent status={item.currentStepStatus} /></div>;
        }
    }),
    createTableColumn<SensitivityStudyResultModel>({
        columnId: "jobs",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>Jobs</div>,
        renderCell: (item: SensitivityStudyResultModel) => {

            return <div style={{ textAlign: "center" }}><JobProgressCount
                calculatedJobs={item.calculatedRows}
                failedJobs={item.failedRows}
                totalJobs={item.totalJobs} /></div>;
        }
    }),
    createTableColumn<SensitivityStudyResultModel>({
        columnId: "created",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>Created</div>,
        renderCell: (item: SensitivityStudyResultModel) => {

            return <div style={{ textAlign: "center" }}><ShowLocalTime date={item.createdAt} /></div>;
        }
    }),

];