import React from "react";
import { IExam } from "../../shared/exams.models";
import { Button, ToolbarButton } from "@fluentui/react-components";
import { DialogFilePicker } from "../../../components/dialog-file-picker/dialog-file-picker.component";
import { FileModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { LoadingService } from "../../../utils/loading-indicator.component";
import { processServerError } from "../../../utils/helpers/error.helper";
import { getFilesClient } from "../../../services/dashboard.service";
import { mapExamPostModelToIExam } from "../../shared/exam.utilities";
import { ArrowImportRegular } from "@fluentui/react-icons";

type ImportExamButtonProps = {
    loadingService: LoadingService;
    onSuccess: (exam: IExam) => void;
}

export const ImportExamButton: React.FC<ImportExamButtonProps> = (props) => {
    const [showFilePicker, setShowFilePicker] = React.useState<boolean>(false);
    const onFileSelected = (file: FileModel) => {
        setShowFilePicker(false);
        props.loadingService.showLoading("Importing exam...", async (hideMessage) => {
            try {
                const filesClient = getFilesClient();
                const fileResp = await filesClient.downloadFileLatest(file.uniqueIdentifier);
                const examString: string = await BlobToStringAsync(fileResp.data);
                console.log("examString", examString);
                const examObject = JSON.parse(examString);
                console.log("examObject", examObject);
                const mappedExam = mapExamPostModelToIExam(examObject);
                props.onSuccess(mappedExam);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while importing exam.");
            } finally {
                hideMessage();
            }

        });


    }

    return <>
        <ToolbarButton appearance="subtle" icon={<ArrowImportRegular/>} onClick={() => { setShowFilePicker(true); }}>Import Exam</ToolbarButton>
        {showFilePicker && <DialogFilePicker
            isOpened={showFilePicker}
            filterFileTypes={["json"]}
            onClose={() => { setShowFilePicker(false); }}
            onFileSelected={onFileSelected} />}
    </>
}

function BlobToStringAsync(blob: Blob) {
    return new Promise((resolve: (value: string) => void, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
            resolve(event.target.result.toString());
        };

        reader.onerror = function (error) {
            reject(error);
        };

        reader.readAsText(blob);
    });
}