import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth/authConfig';
import { BrowserRouter } from 'react-router-dom';
export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  console.log("msalInstance.addEventCallback", event);

  if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) 
  {
    /**
     * When users are using multiple MS accounts in a browser,
     * when accessing Dashboard multiple errors would appear.
     * This should fix that case.
     */
    
    window.localStorage.clear(); // Clear local storage    
    window.location.reload(); // Refresh
  }
  else if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) 
    {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  
    <BrowserRouter>
      <App pca={msalInstance} />
    </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
