import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import * as React from "react";
import { loginRequest } from "../../auth/authConfig";
import { WelcomePage } from "../welcome/welcome.page";
import { HubConnection } from "@microsoft/signalr";
import { Button, Spinner } from "@fluentui/react-components";
import sidebarLogo from '../../assets/sidebar-logo.png';
import { useSearchParams } from "react-router-dom";
import { useLoading } from "../../utils/loading-indicator.component";
import { getErrorMessagesFromResponse, processServerError } from "../../utils/helpers/error.helper";
import { getUsersClient } from "../../services/dashboard.service";
type HomePageProps = {
    hubConnection: HubConnection;
}

export const LoginRegisterOverlay: React.FC<HomePageProps> = (props) => {

    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [searchParams, setSearchParams] = useSearchParams();

    const [confirmationToken, setConfirmationToken] = React.useState<string>();
    const [userPrincipalName, setUserPrincipalName] = React.useState<string>();
    const [errorMessages, setErrorMessages] = React.useState<string[]>();
    const [isLoading, loadingService] = useLoading();

    React.useEffect(() => {

        const confToken = searchParams.get('confirmationToken');
        setConfirmationToken(confToken);
        if (!!confToken) {
            confirmAccount(confToken);
        }

    }, []);


    React.useEffect(() => {
        if (!isAuthenticated) {
            checkIfUserIsLoggedIn();
        }

    }, []);

    const checkIfUserIsLoggedIn = async () => {
        try {
            const account = instance.getAllAccounts()[0];
            console.log("checkIfUserIsLoggedIn instance:", instance);
            const accessTokenRequest = {
                scopes: ["user.read"],
                account: account,
            };

            const userToken = await instance.ssoSilent(loginRequest);
            console.log("userTokenResp", userToken);

        } catch (error) {
            console.log("checkIfUserIsLoggedIn error:", error);
        }
    }


    const confirmAccount = (token: string) => {
        loadingService.showLoading("Confirming registration...", async (hideMessage) => {
            try {
                const client = getUsersClient();
                const confirmResp = await client.confirmRegistration(token);
                setUserPrincipalName(confirmResp.userPrincipalName);

            } catch (error) {
                console.log("An error occurred while confirming account.", error);
                const errors = getErrorMessagesFromResponse(error, undefined, "An error occurred while confirming account.");
                setErrorMessages(errors);
            } finally {
                hideMessage();
            }

        });
    }

    const onLoginClick = () => {
        instance.loginRedirect(loginRequest);
    }

    const onRegisterClick = () => {
        window.open('https://simulate365.com/registration/', "_blank");
    }

    return <>
        <div style={{
            zIndex: "1000", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "grey", opacity: 0.5,
            display: "flex",
            justifyContent: "center",
            alignItems:  "center" 
        }}>
        </div>

        {!confirmationToken && <div style={{
            zIndex: "1001", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", display: "flex",
            justifyContent: "center",
            alignItems: "flex-start"
        }}>
            <div style={{ padding: "4px", opacity: 1, display: "flex", flexDirection: "row", justifyItems: "center", gap: "15px", zIndex: "99991" }}>
                <Button size="large" appearance="primary"  
                style={{color:"white", width: "200px",borderRadius:"var(--borderRadiusMedium)", border:"var(--strokeWidthThin) solid var(--colorNeutralStroke1)" }} 
                onClick={onRegisterClick}>Register</Button>
                <Button size="large" appearance="secondary" style={{ width: "200px", color:"white", backgroundColor:"#3EB489" }} onClick={onLoginClick}>Log in</Button>
            </div>
        </div>}

        {!!confirmationToken && <div style={{
            zIndex: "1001", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <div style={{ backgroundColor: "white", opacity: 1, maxWidth: "25vw", minWidth: "400px", border: "1px solid black", padding: "20px", }}>
                <div style={{ backgroundImage: `url(${sidebarLogo})`, height: "120px", backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}></div>

                {!isLoading && !!confirmationToken && !!userPrincipalName && <div style={{ margin: "25px 10px" }}>
                    <p >Your account has been activated.</p>
                    <p>This is your username: <b>{userPrincipalName}</b></p>
                    <p>Continue to log in with your username and password.</p>
                </div>}
                {isLoading && <div style={{ margin: "25px 10px" }}>
                    <Spinner size="medium" label="Confirming account..." />
                </div>}
                {!isLoading && !!errorMessages && !!confirmationToken && errorMessages.length > 0 && <div style={{ margin: "25px 10px" }}>
                    <p>{errorMessages[0]}</p>
                </div>}

                <div style={{ display: "flex", flexDirection: "row", justifyItems: "center", gap: "15px", zIndex: "99991" }}>
                    {!confirmationToken && <Button size="large" appearance="outline" style={{ flex: 1 }} onClick={onRegisterClick}>Register</Button>}
                    <Button size="large" appearance="outline" style={{ flex: 1 }} onClick={onLoginClick}>Log in</Button>
                </div>
            </div>
        </div>}
        <WelcomePage hubConnection={props.hubConnection} />
    </>;
}

export default LoginRegisterOverlay;