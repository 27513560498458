import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { HubConnection } from "@microsoft/signalr";
import React from "react";
import { FileModel, FilterResultModel, ShareDefinitionSourceType } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { SaveFileModal, SaveFileModel } from "../../components/save-file-modal/save-file-modal.component";
import { getFileExtension, getFileNameWithoutExtension } from "../../files/file-type-icon/file-type-icon.helpers";
import { toast } from "react-toastify";
import { processServerError } from "../../utils/helpers/error.helper";
import { SensitivityStudyDetails, SensitivityStudyDetailsType } from "../sensitivity-study-details/sensitivity-study-details.component";
import { SensitivityStudy } from "../edit-sensitivity-study/edit-sensitivity-study.interfaces";
import { CloneSharedStudyPostModel } from "../../swagger-clients/sensitivity-studies-api-clients.service";
import { getSensitivityStudiesClient } from "../../services/sensitivity-studies.service";

type SensitivityStudyDetailsModalProps = {
    isOptimumTest: boolean;
    studyId: number;
    studyName?: string;
    isOpened?: boolean;
    hubConnection: HubConnection;
    onClose?: () => void;
}

export const SensitivityStudyDetailsModal: React.FC<SensitivityStudyDetailsModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [selectedFilter, setSelectedFilter] = React.useState<FilterResultModel>();
    const [selectedStudy, setSelectedStudy] = React.useState<SensitivityStudy>();
    const [showSaveFileModal, setShowSaveFileModal] = React.useState<boolean>(false);
    const sensitivityStudyDetailsRef = React.useRef<SensitivityStudyDetailsType>();

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    const onClose = () => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }

    const onCloneSharedSensitivityStudyClick = (scenario: SensitivityStudy, file: FileModel, filter: FilterResultModel) => {
        setSelectedFile(file);
        setSelectedStudy(scenario);
        setSelectedFilter(filter);
        setShowSaveFileModal(true);
    }

    const onCloneStudyFilterAndFlowsheetToDashboard = async (data: SaveFileModel) => {
        const messageId = sensitivityStudyDetailsRef.current?.showLoadingMessage(`Cloning ${(props.isOptimumTest ? "optimum test" : "study")}...`);
        try {
            setShowSaveFileModal(false);
            const model = new CloneSharedStudyPostModel({
                studyId: props.studyId,
                shareSourceType: props.isOptimumTest ? ShareDefinitionSourceType.OptimumTest : ShareDefinitionSourceType.MssStudy,
                filename: `${data.name}.${data.extension}`,
                destinationUniqueId: data.parentDirectoryUniqueId,
                conflictAction: data.conflictAction
            });
            const client = getSensitivityStudiesClient();
            await client.cloneSharedStudy(model);

            toast.success(` ${(props.isOptimumTest ? "Optimum test" : "Sensitivity study")} cloned successfully.`);
            onClose();
        } catch (error) {
            processServerError(error, undefined, `An error occurred while cloning shared ${(props.isOptimumTest ? "optimum test" : "study")}.`);
        } finally {
            sensitivityStudyDetailsRef.current?.hideLoadingMessage(messageId);
        }


    }


    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onClose();
        }

    }}>
        <DialogSurface style={{ minWidth: "90vw" }}>

            <DialogBody style={{ height: "90vh" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onClose} icon={<Dismiss24Filled />} />}
                    style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    {props.isOptimumTest && <span>{props.studyName ? `Shared with me >  Optimum Tests (OT) > ${props.studyName}` : "Shared Optimum Test (OT) details"}</span>}
                    {!props.isOptimumTest && <span>{props.studyName ?`Shared with me >  Multivariate Sensitivity Studies (MSS) > ${props.studyName}` : "Shared Multivariate Sensitivity Study (MSS) details"}</span>}

                </DialogTitle>
                <DialogContent>
                    <SensitivityStudyDetails
                        ref={sensitivityStudyDetailsRef}
                        isSharedView
                        isOptimumTester={props.isOptimumTest}
                        studyId={props.studyId}
                        onCloneSharedStudyClick={onCloneSharedSensitivityStudyClick}
                        hubConnection={props.hubConnection} />

                    {selectedFile && selectedFilter && selectedStudy && showSaveFileModal &&
                        <SaveFileModal
                            isOpened={true}
                            modalTitle={`Clone ${(props.isOptimumTest ? "Optimum Test" : "Study")} and Flowsheet`}
                            saveButtonLabel="Clone"
                            defaultName={getFileNameWithoutExtension(selectedFile.name)}
                            fileExtension={getFileExtension(selectedFile.name)}
                            fileExtensionText={`DWSIM file (*.${getFileExtension(selectedFile.name)})`}
                            onHide={() => { setShowSaveFileModal(false); setSelectedFile(undefined); setSelectedFilter(undefined); }}
                            onSuccess={onCloneStudyFilterAndFlowsheetToDashboard}
                        />
                    }

                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}