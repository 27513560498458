import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, DialogActions } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { GLOBAL_STYLES } from "../../styles";
import { ExamResultModel, ExportExamToJsonPostModel } from "../../swagger-clients/s365-take-home-exams-v2-clients.service";
import { SaveFileModal, SaveFileModel } from "../../components/save-file-modal/save-file-modal.component";
import { LoadingService } from "../../utils/loading-indicator.component";
import { processServerError } from "../../utils/helpers/error.helper";
import { getExamsClient } from "../../services/take-home-exams.service";
import { toast } from "react-toastify";
import { IExam } from "../shared/exams.models";

type ExportTakeHomeExamProps = {
    exam: IExam;
    loadingService: LoadingService;
    onClose: () => void;
}

export const ExportTakeHomeExam: React.FC<ExportTakeHomeExamProps> = (props) => {
    const [showSaveDialog, setShowSaveDialog] = React.useState<boolean>(true);



    const onExportExamClicked = (data: SaveFileModel) => {
        setShowSaveDialog(false);
        props.loadingService.showLoading("Exporting exam layout...", async (hideMessage) => {
            try {
                const client = getExamsClient();
                const model = new ExportExamToJsonPostModel({
                    examId: props.exam.id!,
                    filename: `${data.name}.${data.extension}`,
                    conflictAction: data.conflictAction,
                    parentDirectoryUniqueId: data.parentDirectoryUniqueId
                });
                await client.exportExamToJsonFile(model);
                toast.success(`Exam Layout exported successfully.`);
                props.onClose();

            } catch (error) {
                processServerError(error, undefined, "An error occurred while exporting exam layout.");
            } finally {
                hideMessage();
            }

        });


    }


    return <div>
        <SaveFileModal isOpened={showSaveDialog}
            modalTitle="Save to Dashboard"
            saveButtonLabel="Save"
            defaultName={props.exam.name}
            fileExtension="json"
            fileExtensionText="Json File (*.json)"
            onSuccess={onExportExamClicked}
            onHide={() => { props.onClose(); }}
        />
    </div>

}