import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getFilesClient, getFiltersClient, getUserToken } from "../../services/dashboard.service";
import { getProcessingClient, getSensitivityStudiesClient } from "../../services/sensitivity-studies.service";
import { BreadcrumbItem, FileModel, FileWithBreadcrumbsResponseModel, FilterParameterType, FilterQuery, FilterResultModel, IFilterQuery, InputFilterParameterResultModel, OutputFilterParameterResultModel, ShareDefinitionSourceType } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { _copyAndSort } from "../../utils/helpers/array.helpers";
import { processServerError } from "../../utils/helpers/error.helper";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { ErrorResultModel, ProcessingPostModel, ResubmitRowPostModel, RowModel, SensitivityStudyQuery, StudyStep, StudyStepStatus, StudyType } from "../../swagger-clients/sensitivity-studies-api-clients.service";
import { SensitivityStudy, SensitivityStudyInputParameter, SensitivityStudyOutputParameter } from "../edit-sensitivity-study/edit-sensitivity-study.interfaces";
import { SensitivityStudiesRouteParams } from "../sensitivity-studies-list.component";
import { getCompletedJobsText, GetStudyParamValueFromFilterParam, GetStudyStepStatusText, GetStudyStepText, MapFilterParameterTypeToCustomStudyInputParameterType } from "../utilities/study.utilities";
import { saveAs } from 'file-saver';
import { settings } from "../../App";
import { Alert } from "@fluentui/react-components/unstable";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import { Label, Menu, MenuButton, MenuButtonProps, MenuItem, MenuList, MenuPopover, MenuTrigger, Tab, TabList, Toolbar, ToolbarButton, tokens } from "@fluentui/react-components";
import { FolderAdd16Regular, DocumentArrowUp16Regular, FolderArrowUp16Regular, Edit20Regular, Copy20Regular, DocumentTable20Regular, ArrowSync20Regular, ArrowDownload20Regular, ArrowClockwise20Regular, Play20Regular, Stop20Regular, InfoFilled, DocumentPdfRegular } from "@fluentui/react-icons";
import nl2br from 'nl2br';
import { ApplicationName } from "../../components/application-name/application-name.component";
import { IChoiceGroupOption } from "../../utils/shared.types";
import { ConfirmationDialog } from "../../components/confirmation-dialog/confirmation-dialog.component";
import { StudyInputParametersDetailsTab } from "./input-parameter-tab-details.component";
import { PrismCode } from "../../components/prism-code/prism-code.component";
import 'prismjs/themes/prism.css';
import { StudyOutputParametersDetailsTab } from "./output-parameters-tab-details.component";
import { SensivityStudyData, SensivityStudyDataType } from "./sensitivity-study-data/sensitivity-study-data.component";
import { OptimumGraphsTab, OptimumGraphsTabType } from "./optimum-graphs-tab/optimum-graphs-tab.component";
import { DispatcherUsageStatistics, DispatcherUsageStatisticsType } from "../../components/dispatcher-usage-statistics/dispatcher-usage-statistics.component";
import "./sensitivity-study-details.styless.scss";
import { JobProgressCount } from "../../components/job-progress-count/job-progress-count.component";
import { ExportDataExcel } from "./export-data-excel.component";
import { CreatePDFReport } from "./create-pdf-report/create-pdf-report.component";
import { ThumbnailImage } from "../../files/thumbnail/thumbnail-image/thumbnail-image.component";
import { HubConnection } from "@microsoft/signalr";
import { ThumbnailModal } from "../../files/thumbnail/thumbnail-modal/thumbnail-modal.component";

type SensitivityStudyDetailsProps = {
    studyId?: number;
    isOptimumTester?: boolean;
    isDwsimApp?: boolean;
    isExample?: boolean;
    isSharedView?: boolean;
    baseReactRoute?: string;
    baseOptReactRoute?: string;
    hubConnection: HubConnection;
    onCloneSharedStudyClick?: (study: SensitivityStudy, file: FileModel, filter: FilterResultModel) => void;
};

export type SensitivityStudyDetailsType = {
    showLoadingMessage: (message: string) => string;
    hideLoadingMessage: (messageId: string) => void;
}

const emptyBreadcrumbs = [{ name: "My Work" } as BreadcrumbItem];

export const SensitivityStudyDetails = React.forwardRef<SensitivityStudyDetailsType, SensitivityStudyDetailsProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            showLoadingMessage(message: string) {
                const messageId = loadingService.showMessage(message);
                return messageId;

            },
            hideLoadingMessage(messageId) {
                loadingService.hideMessage(messageId);
            },
        }));


    const navigate = useNavigate();
    const routeParams = useParams<SensitivityStudiesRouteParams>();
    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [filter, setFilter] = React.useState<FilterResultModel>(null);
    const [showStartProcessingDialog, SetShowStartProcessingDialog] = React.useState<boolean>(false);
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [selectedStudy, setSelectedStudy] = React.useState<SensitivityStudy>(undefined);
    const [isStudyLoading, SetIsStudyLoading] = React.useState<boolean>(false);
    const [isFilterLoading, SetIsFilterLoading] = React.useState<boolean>(false);
    const [isFileLoading, SetIsFileLoading] = React.useState<boolean>(false);
    const [includeData, setIncludeData] = React.useState<boolean>(false);
    const [includeCovergedData, setIncludeConvergedData] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const [selectedTab, setSelectedTab] = React.useState<string>("input-parameters");
    const dataTabRef = React.useRef<SensivityStudyDataType>();
    const usageStatisticsRef = React.useRef<DispatcherUsageStatisticsType>();
    const optimumGraphTabRef = React.useRef<OptimumGraphsTabType>();
    const [showExportModal, setShowExportModal] = React.useState<boolean>(false);
    const [showCreatePDFReport, setShowCreatePDFReport] = React.useState<boolean>(false);
    const [showThumbnailModal, setShowThumbnailModal] = React.useState<boolean>(false);
    const [showStopProcessingDialog, setShowStopProcessingDialog] = React.useState<boolean>(false);

    const studyId = !!props.studyId ? props.studyId : +routeParams.studyId;

    React.useEffect(() => {
        getStudyWithLoading();
    }, []);


    // File needs to be loaded before sensitivity study can be loaded
    React.useEffect(() => {
        if (filter?.fileUniqueIdentifier) {
            getFile();
        }
    }, [filter?.fileUniqueIdentifier]);

    React.useEffect(() => {
        (async () => {
            if (!!selectedStudy) {
                const filterResp = await getFilter(selectedStudy);
                setFilter(filterResp);
            }
        })();

    }, [selectedStudy]);


    const getFile = async () => {
        loadingService.showLoading("Getting file data...", async (hideMessage) => {
            try {
                SetIsFileLoading(true);
                const client = getFilesClient();
                const sourceType = props.isOptimumTester ? ShareDefinitionSourceType.OptimumTest : ShareDefinitionSourceType.MssStudy;
                const resp = await client.getSharedFileLatest(selectedStudy.flowsheetDriveId, sourceType, studyId.toString(), true);
                if (resp) {
                    setSelectedFile(resp.file);
                    setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
                }
                return resp;

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting file information.");
            } finally {
                SetIsFileLoading(false);
                hideMessage();
            }
        });

    }

    const getStudyWithLoading = async () => {
        const messageId = loadingService.showMessage(`Loading ${props.isOptimumTester ? "optimum test" : "study"}...`);
        await getStudy();
        loadingService.hideMessage(messageId);
    }

    const getStudy = async () => {
        SetIsStudyLoading(true);


        try {
            //  console.log("getSensitivityStudyAsync action",action);
            const client = getSensitivityStudiesClient();
            let study: SensitivityStudy = await client.getSensitivityStudyById(studyId) as SensitivityStudy;


            if (study.sensitivityStudyInputParameters && study.sensitivityStudyInputParameters.length > 0) {
                study = await getFilterParamtersData(study);
            }
            console.log("Loaded study:", study);
            setSelectedStudy(study);

        }
        catch (error) {
            processServerError(error, undefined, `An error ocurred while trying to get ${props.isOptimumTester ? "optimum test" : "senstivity study"}.`);
        }
        finally {
            SetIsStudyLoading(false);
        }

    }

    const getFilter = async (sensitivityStudy: SensitivityStudy) => {
        try {
            SetIsFilterLoading(true);
            const client = getFiltersClient();
            const sourceType = props.isOptimumTester ? ShareDefinitionSourceType.OptimumTest : ShareDefinitionSourceType.MssStudy;
            const filterResult = await client.getSharedFilter(sourceType, sensitivityStudy.id.toString(),
                +sensitivityStudy.filterId, +sensitivityStudy.filterVersionId);
            return filterResult;
        }
        catch (error) {
            console.log("An error ocurred while trying to get filter.", error);
        } finally {
            SetIsFilterLoading(false);
        }
    }

    const getFilterParamtersData = async (study: SensitivityStudy) => {

        try {


            let filter: FilterResultModel = await getFilter(study);
            filter.inputFilterParameters = _copyAndSort<InputFilterParameterResultModel>(filter.inputFilterParameters, "order", false);
            filter.outputFilterParameters = _copyAndSort<OutputFilterParameterResultModel>(filter.outputFilterParameters, "order", false);
            console.log("Study:", study, " Filter", filter);


            const inputStudyParams = filter && filter.inputFilterParameters && study &&
                study.sensitivityStudyInputParameters && study.sensitivityStudyInputParameters.length > 0 ?
                filter.inputFilterParameters.map((filterParam) => {
                    const inputParam = study.sensitivityStudyInputParameters.find(x => x.inputFilterParameterId == filterParam.id);
                    if (filterParam) {
                        return {
                            ...inputParam,
                            inputFilterParameter: {
                                name: filterParam.name,
                                alias: filterParam.alias
                            },
                            parameterType: MapFilterParameterTypeToCustomStudyInputParameterType(filterParam.parameterType),
                            filterParameterValue: GetStudyParamValueFromFilterParam(filterParam)
                        } as unknown as SensitivityStudyInputParameter
                    }

                    return inputParam;
                }) : [];




            const outputStudyParams = filter && filter.outputFilterParameters && filter.outputFilterParameters.length > 0 ? filter.outputFilterParameters.map((param) => {
                const outputParam = study.sensitivityStudyOutputParameters.find(x => x.outputFilterParameterId == param.id);
                return {
                    id: outputParam.id,
                    outputFilterParameterId: param.id,
                    outputFilterParameter: { ...param } as any,
                    isFilterFormula: param.parameterType == FilterParameterType.Formula ? true : false,
                    filterFormulaValue: param.formula
                } as unknown as SensitivityStudyOutputParameter
            }) : [];




            return { ...study, sensitivityStudyInputParameters: inputStudyParams, sensitivityStudyOutputParameters: outputStudyParams } as SensitivityStudy;


        } catch (error) {
            const objectName = props.isOptimumTester ? "optimum test" : "study";
            processServerError(error, undefined, `An error occurred while trying to get filter for ${objectName}.`);
        }
    }

    const onConfirmStartProcessingClick = () => {
        loadingService.showLoading(
            "Starting the calculation...",
            (hideMessage) => {
                onConfirmStartProcessing()
                    .finally(() => hideMessage());
            }
        );
    }

    const onResumeProcessingClick = () => {
        loadingService.showLoading(
            " Resuming the calculation...",
            (hideMessage) => {
                onConfirmStartProcessing(true)
                    .finally(() => hideMessage());
            }
        );
    }

    const onConfirmStartProcessing = async (isResume: boolean = false) => {

        try {
            // Used to update selected study, if in meantime jobs were created
            await getStudy();
            if (selectedStudy.currentStep == StudyStep.CreateJobs && selectedStudy.currentStepStatus !== StudyStepStatus.SuccessfullyFinished) {
                toast.error("Calculation can be started only after all jobs are created.");
                return;
            }

            const client = getProcessingClient();
            await client.startProcessing({ studyId: selectedStudy.id } as ProcessingPostModel);
            if (isResume) {
                toast.success("Calculation resumed successfully.");
            } else {
                toast.success("Calculation started successfully.");
            }

            await getStudy();

        } catch (error) {
            const actionName = isResume ? "resuming" : "starting";
            processServerError(error, undefined, `An error occurred while ${actionName} the calculation.`);
        }
        SetShowStartProcessingDialog(false);

    }

    const onStopProcessing = async () => {
        try {
            const client = getProcessingClient();
            await client.stopProcessing({ studyId: selectedStudy.id } as ProcessingPostModel);
            toast.success("Calculation stopped successfully.");
            await getStudy();

        } catch (error) {
            toast.error("An error occurred while stopping the calculation.");
            console.log("An error occurred while stopping the calculation.", error);
        }
    }
    const onStopProcessingClick = async () => {

        loadingService.showLoading(
            "Stopping the calculation...",
            (hideMessage) => {
                onStopProcessing()
                    .finally(() => hideMessage());
            }
        );


    }

    const onResubmitToDispatcher = async (filerow: RowModel) => {
        try {


            const client = getProcessingClient();
            var model = {
                studyId: studyId,
                rowId: filerow.id
            } as ResubmitRowPostModel;
            await client.resubmitRow(model);
            dataTabRef.current?.reloadData();
            toast.success(`Row with id ${filerow.id} was resubmitted.`);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to resubmit to dispatcher.");
        }
    }

    const onResubmitToDispatcherClick = async (filerow: RowModel) => {
        loadingService.showLoading(
            "Resubmitting to dispatcher...",
            (hideMessage) => {
                onResubmitToDispatcher(filerow)
                    .finally(() => hideMessage());
            }
        );
    }

    const onRefreshClick = async () => {

        try {
            getStudy();
            // console.log("dataTabRef", dataTabRef);
            if (dataTabRef && dataTabRef.current) {
                dataTabRef.current.reloadData();
            }
            if (usageStatisticsRef && usageStatisticsRef.current) {
                usageStatisticsRef.current.GetUsageStatistics();
            }
            if (optimumGraphTabRef && optimumGraphTabRef.current) {
                optimumGraphTabRef.current.getGraphData();
            }

        } catch (error) {
            const objectName = props.isOptimumTester ? "optimum test" : "study";
            processServerError(error, undefined, `An error occurred while refreshing ${objectName}.`);
        }
    }

    const resubmitFailedRows = async () => {
        try {


            const client = getProcessingClient();

            await client.resubmitFailedRows(selectedStudy.id);
            toast.success(`Recalculation of failed jobs has started.`);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to resubmit failed jobs to dispatcher.");
        }
    }

    const downloadPdf = async () => {
        try {
            var accessToken = await getUserToken();
            var data = {
                studyId: selectedStudy.id,
                sensitivityStudyServiceUrl: settings.sensitivityStudiesServiceUrl,
                studyName: selectedStudy.name,
                accessToken: accessToken
            };

            var requestOptions = {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            } as RequestInit;

            const response = await fetch(`${settings.pdfGeneratorServiceUrl}/api/studies/pdf-converter`, requestOptions);
            if (response.status == 200) {
                console.log("Pdf resp", response);
                //  const stream= new Response(response as any);
                const blob = await response.blob();
                saveAs(blob, `${selectedStudy.name}.pdf`);
            } else {
                var respText = await response.json();
                console.log("respText", respText);
                const error = respText as ErrorResultModel;
                if (error && error.errors && error.errors.length > 0) {
                    throw error;
                } else
                    throw "An error occurred while downloading PDF.";
            }
        } catch (error) {
            processServerError(error, undefined, "An error occurred while downloading PDF.");
        }
    }

    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }

    const onCloneClick = () => {
        if (!!props.isSharedView) {
            props.onCloneSharedStudyClick(selectedStudy, selectedFile, filter);
        } else {
            navigate(`${props.baseReactRoute ? props.baseReactRoute : ""}/files/${filter?.fileUniqueIdentifier}/${!props.isOptimumTester ? 'sensitivity-studies' : "optimum-tester"}/edit?cloneId=${studyId}`);
        }
    }

    const getToolbarItems = () => {
        let commandBarItems = [];

        if (selectedStudy && !props.isSharedView && (selectedStudy.currentStep == StudyStep.CalculateInitialJob ||
            (selectedStudy.currentStep == StudyStep.CalculateJobs && selectedStudy.currentStepStatus == StudyStepStatus.Ready))) {
            commandBarItems.push(<ToolbarButton
                appearance='subtle'
                icon={<Edit20Regular />}
                disabled={selectedStudy && !!selectedStudy.isDeleted}
                onClick={() => navigate(`${props.baseReactRoute ? props.baseReactRoute : ""}/files/${filter?.fileUniqueIdentifier}/${!props.isOptimumTester ? 'sensitivity-studies' : "optimum-tester"}/edit/${selectedStudy.id}`)}
            >Edit</ToolbarButton>);
        }


        commandBarItems.push(
            <ToolbarButton
                appearance='subtle'
                icon={<Copy20Regular />}
                onClick={onCloneClick}
            >Clone</ToolbarButton>);

        if (!!props.isSharedView) {
            return commandBarItems;
        }

        commandBarItems.push(<ToolbarButton
            appearance='subtle'
            icon={<ArrowClockwise20Regular />}
            onClick={() => { onRefreshClick(); }}
        >Refresh</ToolbarButton>
        );


        commandBarItems.push(
            <Menu positioning="below-start">
                <MenuTrigger>
                    {(triggerProps: MenuButtonProps) => {
                        return <MenuButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                            appearance='subtle'
                            icon={<DocumentTable20Regular />}
                            {...triggerProps}
                        >Export to Excel</MenuButton>
                    }}
                </MenuTrigger>

                <MenuPopover>
                    <MenuList>
                        <MenuItem key="dataonly"
                            icon={<DocumentTable20Regular />}
                            disabled={selectedStudy && !!selectedStudy.isDeleted}
                            onClick={() => { setShowExportModal(true); setIncludeData(false); setIncludeConvergedData(false); }}
                        >Input data only</MenuItem>

                        <MenuItem key="convergeddataonly"
                            icon={<DocumentTable20Regular />}
                            disabled={selectedStudy && !!selectedStudy.isDeleted}
                            onClick={() => { setShowExportModal(true); setIncludeConvergedData(true); }}
                        >Converged data only</MenuItem>

                        <MenuItem key="alldata"
                            icon={<DocumentTable20Regular />}
                            disabled={selectedStudy && !!selectedStudy.isDeleted}
                            onClick={() => { setShowExportModal(true); setIncludeData(true); setIncludeConvergedData(false); }}
                        >All data</MenuItem>

                    </MenuList>
                </MenuPopover>
            </Menu>

        );

        commandBarItems.push(<ToolbarButton appearance='subtle'
            icon={<DocumentPdfRegular />}
            disabled={selectedStudy && !!selectedStudy.isDeleted}
            onClick={() => {
                setShowCreatePDFReport(true);
            }}>Create PDF Report</ToolbarButton>);

        if (selectedStudy && selectedStudy.currentStep == StudyStep.CalculateJobs && selectedStudy.currentStepStatus != StudyStepStatus.Ready) {
            commandBarItems.push(
                <Menu positioning="below-start">
                    <MenuTrigger>
                        {(triggerProps: MenuButtonProps) => {
                            return <MenuButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                                appearance='subtle'
                                icon={<ArrowSync20Regular />}
                                {...triggerProps}
                            >Resubmit jobs</MenuButton>
                        }}
                    </MenuTrigger>

                    <MenuPopover>
                        <MenuList>
                            <MenuItem key="resubmit-failed"
                                icon={<ArrowSync20Regular />}
                                disabled={selectedStudy && !!selectedStudy.isDeleted}
                                onClick={() => {
                                    loadingService.showLoading(
                                        "Resubmitting failed jobs...",
                                        (hideMessage) => {
                                            resubmitFailedRows()
                                                .finally(() => hideMessage());
                                        });
                                }}
                            >Resubmit all failed jobs to Dispatcher</MenuItem>


                        </MenuList>
                    </MenuPopover>
                </Menu>

            );
        }
        if (selectedStudy && selectedStudy.currentStep == StudyStep.CalculateJobs && selectedStudy.currentStepStatus == StudyStepStatus.Ready) {


            commandBarItems.push(<ToolbarButton appearance='subtle' key="start"
                icon={<Play20Regular />}
                disabled={selectedStudy && !!selectedStudy.isDeleted}
                onClick={() => { SetShowStartProcessingDialog(true); }}>Start calculation</ToolbarButton>);
        }

        if (selectedStudy && selectedStudy.currentStep == StudyStep.CalculateJobs &&
            selectedStudy.currentStepStatus == StudyStepStatus.Stopped) {
            commandBarItems.push(<ToolbarButton appearance='subtle' key="resume"
                icon={<Play20Regular />}
                disabled={selectedStudy && !!selectedStudy.isDeleted}
                onClick={() => { onResumeProcessingClick(); }}>Resume calculation</ToolbarButton>);
        }

        if (selectedStudy && selectedStudy.currentStep == StudyStep.CalculateJobs && selectedStudy.currentStepStatus == StudyStepStatus.InProgress) {
            commandBarItems.push(<ToolbarButton appearance='subtle' key="stop"
                icon={<Stop20Regular />}
                disabled={selectedStudy && !!selectedStudy.isDeleted}
                onClick={() => { setShowStopProcessingDialog(true); }}>Stop calculation</ToolbarButton>);
        }

        return commandBarItems;
    }
    const studyParamterRangeOptions: IChoiceGroupOption[] = [
        { key: '0', text: 'Min and max values' },
        { key: '1', text: 'Initial value with steps before and after' },

    ];

    const flowsheetParentDirectoryUniqueId = breadcrumbs && breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].uniqueIdentifier : undefined;



    return <div className="content-wrapper">

        {!props.isExample && <div className='toolbar__wrapper'>
            <Toolbar>
                {getToolbarItems()}
                <LoadingIndicator loadingService={loadingService} />
            </Toolbar>
        </div>}

        {!props.isExample && !props.isSharedView && <div className='breadcrumbs-wrapper'>
            <Breadcrumbs>
                {breadcrumbs.map((item: BreadcrumbItem) => {
                    return <Breadcrumb
                        key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                        onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                })}
                {selectedFile &&
                    <Breadcrumb
                        key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                        onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/${props.isOptimumTester ? "optimum-tester" : "sensitivity-studies"}`)}
                    >{selectedFile.name}</Breadcrumb>}
                <Breadcrumb key={`breadcrumb-sensitivity-studies`} active={true}>{props.isOptimumTester ? "Optimum Test" : "Multivariate Sensitivity Study"}</Breadcrumb>

            </Breadcrumbs>
        </div>}


        {selectedStudy && selectedStudy.errorMessage && selectedStudy.errorMessage.length > 0 &&
            <Alert intent="error" >
                <div style={{ width: "100%" }}>
                    <p>  An error occurred while executing step "{GetStudyStepText(selectedStudy.currentStep)}". </p>
                    <div dangerouslySetInnerHTML={{ __html: `${nl2br(selectedStudy.errorMessage)}` }}></div>
                </div>

            </Alert>}

        {props.isOptimumTester && selectedStudy && selectedStudy.currentStep == StudyStep.CalculateInitialJob
            && (selectedStudy.currentStepStatus == StudyStepStatus.Ready || selectedStudy.currentStepStatus == StudyStepStatus.InProgress) &&
            <Alert intent="info" className="alert--info-global" >
                Waiting for initial job to be calculated. Please refresh this page in a few seconds.
            </Alert>
        }

        <div className="study-details">
            <div style={{ display: "flex", gap: "10px" }}>
                <div className="input-form" style={{ flex: 1 }}>

                    <div className="input-form-item input-form-item--without-margin">
                        <Label className="input-form-label">
                            Study name:
                        </Label>
                        <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{selectedStudy?.name}</p>
                    </div>

                    <div className="input-form-item input-form-item--without-margin">
                        <Label className="input-form-label">
                            Filter:
                        </Label>
                        {!props.isDwsimApp && !props.isExample && !props.isSharedView &&
                            <a href={filter ? `/files/${filter?.fileUniqueIdentifier}/filters/${filter.id}/details` : '#'}
                                style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{filter ? filter.name : ""}</a>
                        }
                        {!props.isDwsimApp && (props.isExample || props.isSharedView) && <span style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{filter ? filter.name : ""}</span>}
                    </div>

                    <div className="input-form-item input-form-item--without-margin" >
                        <Label className="input-form-label">Current step:</Label>
                        <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{GetStudyStepText(selectedStudy?.currentStep)}</p>
                    </div>

                    <div className="input-form-item input-form-item--without-margin" >
                        <Label className="input-form-label">Status:</Label>
                        <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{GetStudyStepStatusText(selectedStudy?.currentStepStatus)}</p>
                    </div>

                    <div className="input-form-item input-form-item--without-margin" >
                        <Label className="input-form-label">Jobs:</Label>
                        <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>
                            <JobProgressCount
                                totalJobs={selectedStudy?.totalJobs}
                                calculatedJobs={selectedStudy?.calculatedRows}
                                failedJobs={selectedStudy?.failedRows} />
                        </p>
                    </div>
                </div>

                {filter && props.hubConnection &&
                    <div className="thumbnail--wrapper" style={{ flex: 1, marginTop: "5px" }}>
                        <Label className="group-label">Thumbnail:</Label>
                        <div style={{ flex: 1 }}>
                            <ThumbnailImage
                                fileUniqueIdentifier={filter.fileUniqueIdentifier!}
                                fileId={filter.fileId!}
                                fileVersionNumber={filter.fileVersionNumber!}
                                fileExtension={"dwxmz"}
                                onClick={() => { setShowThumbnailModal(true) }}
                                hubConnection={props.hubConnection}
                                asBackground={true}
                                fullImage={true}
                            />
                            {selectedFile && showThumbnailModal &&
                                <ThumbnailModal
                                    file={selectedFile}
                                    hubConnection={props.hubConnection!}
                                    isOpened={showThumbnailModal}
                                    onClose={() => setShowThumbnailModal(false)}
                                />}
                        </div>
                    </div>}
            </div>

        </div>

        <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => setSelectedTab(data.value as string)}>
            <Tab key="input-tab" value="input-parameters" >Input parameters</Tab>
            <Tab key="output-tab" value="output-parameters">Output parameters</Tab>
            <Tab key="data-tab" value="data">Data</Tab>
            {props.isOptimumTester && <Tab key="graphs" value="graphs">Graphs</Tab>}
            {!props.isExample && <Tab key="statistics-tab" value="statistics">Statistics</Tab>}
        </TabList>
        <div className="tab-content">
            {selectedTab == "input-parameters" && selectedFile && selectedStudy && <>
                <StudyInputParametersDetailsTab
                    isLoading={isStudyLoading || isFilterLoading || isFileLoading}
                    filter={filter}
                    selectedStudy={selectedStudy}
                    selectedFile={selectedFile} />
                <div className="input-form-item" style={{ marginTop: '20px' }}>
                    <Label
                        style={{
                            fontWeight: 600,
                            width: "30%",
                            textAlign: "right",
                            maxWidth: "250px",
                            paddingRight: "22px"

                        }}>Input constraining function:</Label>

                    <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
                        <PrismCode code={selectedStudy?.inputConstraints ?? ""} language="js" />
                    </div>
                </div>
            </>}

            {selectedTab == "output-parameters" && selectedFile && selectedStudy &&
                <StudyOutputParametersDetailsTab
                    isLoading={isStudyLoading || isFilterLoading || isFileLoading}
                    filter={filter}
                    selectedStudy={selectedStudy}
                    selectedFile={selectedFile} />}
            {selectedTab == "data" && selectedStudy && filter && selectedFile &&
                <SensivityStudyData
                    isExample={props.isExample}
                    ref={dataTabRef}
                    isLoading={isLoading}
                    selectedFile={selectedFile}
                    filter={filter}
                    isOptimumTester={props.isOptimumTester}
                    isDwsimApp={props.isDwsimApp}
                    onResubmitToDispatcherClick={onResubmitToDispatcherClick}
                    selectedStudy={selectedStudy}
                />
            }
            {props.isOptimumTester && selectedTab == "graphs" && selectedStudy &&
                <OptimumGraphsTab ref={optimumGraphTabRef} selectedStudy={selectedStudy} />}

            {!props.isExample && selectedTab == "statistics" &&
                <DispatcherUsageStatistics
                    isLoading={isLoading}
                    ref={usageStatisticsRef}
                    loadingService={loadingService}
                    tag={`MSS#${selectedStudy.id}.${selectedStudy.currentVersionId}`}
                />
            }

        </div>

        <ConfirmationDialog
            title="Start calculation"
            subText="Are you sure you want to start the calculation? Your jobs will be submitted for calculation and you won't be able to edit your study anymore."
            isOpened={showStartProcessingDialog}
            onConfirm={() => { onConfirmStartProcessingClick(); }}
            onClose={() => SetShowStartProcessingDialog(false)}
        />

        {showExportModal &&
            <ExportDataExcel
                studyId={selectedStudy?.id}
                studyVersionId={selectedStudy?.currentVersionId}
                flowsheetParentDirectoryUniqueId={flowsheetParentDirectoryUniqueId}
                isVisible={showExportModal}
                includeData={includeData}
                includeCovergedData={includeCovergedData}
                onVisibleChanged={(isVisible) => { setShowExportModal(isVisible); }}
            />}
        {showCreatePDFReport && <CreatePDFReport
            studyId={selectedStudy.id}
            defaultName={selectedStudy.name}
            parentDirectoryUniqueId={breadcrumbs?.[breadcrumbs.length - 1].uniqueIdentifier}
            onHide={() => { setShowCreatePDFReport(false); }} />}

        {showStopProcessingDialog && <ConfirmationDialog
            title="Stop calculation"
            subText="Are you sure you want to stop the calculation? Your current batch of submitted jobs will be calculated but no new jobs will be submitted."
            isOpened={true}
            confirmLabel="Yes"
            cancelLabel="Cancel"
            onConfirm={() => { onStopProcessingClick(); setShowStopProcessingDialog(false); }}
            onClose={() => { setShowStopProcessingDialog(false) }} />}

    </div>
});